import { CiWarning } from 'react-icons/ci';
import { Link } from 'react-router-dom';
import { Button } from '@components/button';
import { ActivateAccountHeader } from './ActivateAccountHeader';

export function ErrorPage({ title, message, type = 'error' }) {
  return (
    <div className="h-[100vh] w-[100%] bg-neutral-100">
      <ActivateAccountHeader />
      <div className="flex h-[calc(100vh-56px)] items-center justify-center">
        <div className="flex max-w-[640px] flex-col items-center justify-center gap-10 rounded-md bg-white p-12 shadow-header">
          <div className="flex flex-col gap-4">
            <div className="text-center">
              <CiWarning
                size={40}
                className={
                  type === 'error' ? 'text-error-500' : 'text-warning-500'
                }
              />
            </div>
            <p
              className={`m-0 text-center text-2xl font-semibold ${type === 'error' ? 'text-error-500' : 'text-warning-500'}`}
            >
              {title}
            </p>

            <p className="m-0 max-w-[440px] text-center">{message}</p>
          </div>
          <div className="flex h-[48px] justify-center gap-4">
            <Link to="/">
              <Button
                className="px-4"
                onClick={() => {}}
                label="Voltar à Página Inicial"
                type="button"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
