import { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import LoadingAnimation from '@components/LoadingAnimation';
import api from '@services/api';
import { useActivateAccount } from '@stores/authentication';

const toastConfig = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

const errorActiveAccountMap = {
  Invalid: 'Token inválido',
  'Token expired': 'Token expirado',
  'CPF already used': 'CPF já utilizado',
  'Email already used': 'Email já utilizado',
  'Google user with different email': 'Usuário Google com email diferente',
};

export function GoogleActiveAccountCallback() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const { accountData, clearAccountData } = useActivateAccount();
  const navigate = useNavigate();
  const loading = useRef(false);

  const activateAccountWithGoogle = useCallback(async () => {
    if (!code || loading.current) return;

    loading.current = true;

    try {
      await api.post('/users/activate', {
        token: accountData.token,
        firstName: accountData.firstName,
        familyName: accountData.familyName,
        email: accountData.email,
        cpf: accountData.cpf.replace(/\D/g, ''),
        password: accountData.password,
        googleToken: code,
      });

      toast.success('Conta ativada com sucesso!', toastConfig);
      clearAccountData();
      await new Promise((resolve) => setTimeout(resolve, 2000));
      navigate('/');
    } catch (error) {
      toast.error(
        errorActiveAccountMap[error?.message] || error?.message,
        toastConfig,
      );
      await new Promise((resolve) => setTimeout(resolve, 2000));
      navigate(`/activate-account/${accountData?.token}`);
    } finally {
      loading.current = false;
    }
  }, [code, navigate, accountData, clearAccountData]);

  useEffect(() => {
    activateAccountWithGoogle();
  }, [activateAccountWithGoogle]);

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <ToastContainer />
      {loading.current && <LoadingAnimation />}
    </div>
  );
}
