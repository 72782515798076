export const menu = [
  {
    id: 'reservas',
    title: 'Reservas',
    href: '/reservations',
    isActive: true,
    permission: ['all'],
    items: [
      {
        id: 'conferencia-de-reservas',
        title: 'Conferência de Reservas',
        type: 'screen',
        href: '/pesquisar-reservas-externas',
      },
      {
        id: 'gestao-de-reservas',
        title: 'Gestão de Reservas',
        type: 'screen',
        href: '/reservas/gerenciar',
      },
    ],
  },
  {
    id: 'financeiro',
    title: 'Financeiro',
    href: '/financeiro',
    isActive: true,
    permission: ['all'],
    items: [
      {
        id: 'gestao-de-faturas',
        title: 'Gestão de Faturas',
        type: 'screen',
        isActive: false,
        href: '/financeiro',
      },
      {
        id: 'perfis-de-comissionamento',
        title: 'Perfis de Comissionamento',
        type: 'screen',
        isActive: false,
        href: 'TBD',
      },
      {
        id: 'multas-e-reembolsos',
        title: 'Multas e Reembolsos',
        type: 'screen',
        href: '/financeiro/multas-reembolsos',
      },
      {
        id: 'gestao-de-recebiveis-de-hoteis',
        title: 'Gestão de recebíveis de hotéis',
        type: 'screen',
        href: '/financeiro/recebiveis-adm',
      },
    ],
  },
  {
    id: 'analises',
    title: 'Análises',
    href: '/analises',
    isActive: true,
    permission: ['all'],
    items: [
      {
        id: 'relatorios',
        title: 'Relatórios',
        type: 'screen',
        href: '/relatorios',
      },
    ],
  },
  {
    id: 'configuracoes',
    title: 'Configurações',
    href: '/configuracoes',
    isActive: true,
    permission: ['administrador'],
    items: [
      {
        id: 'aereo',
        title: 'Aéreo',
        items: [
          {
            id: 'regras-tarifarias',
            title: 'Regras tarifárias',
            type: 'screen',
            href: '/admin/regras-tarifarias',
          },
          {
            id: 'frotas',
            title: 'Frotas',
            type: 'screen',
            href: '/aereo/frota',
          },
          {
            id: 'saidas',
            title: 'Saídas',
            type: 'screen',
            href: '/aereo/saidas',
          },
          {
            id: 'cias-aereas',
            title: 'Cias. Aéreas',
            type: 'screen',
            href: '/admin/companhias',
          },
          {
            id: 'aeroportos',
            title: 'Aeroportos',
            type: 'screen',
            href: '/admin/aeroportos',
          },
          {
            id: 'assentos',
            title: 'Assentos',
            type: 'screen',
            href: '/admin/assentos',
          },
          {
            id: 'classes',
            title: 'Classes',
            type: 'screen',
            href: '/admin/classes',
          },
          {
            id: 'fabricantes',
            title: 'Fabricantes',
            type: 'screen',
            href: '/admin/fabricantes',
          },
          {
            id: 'servicos-de-bordo',
            title: 'Serviços de Bordo',
            type: 'screen',
            href: '/admin/servicos-bordo',
          },
        ],
      },
      {
        id: 'sistemas',
        title: 'Sistemas',
        items: [
          {
            id: 'empresas',
            title: 'Empresas',
            type: 'screen',
            href: '/admin/empresas',
          },
          {
            id: 'usuarios',
            title: 'Usuários',
            type: 'screen',
            href: '/admin/usuarios',
          },
          {
            id: 'regras-de-pagamento',
            title: 'Regras de pagamento',
            type: 'screen',
            isActive: false,
            href: 'TBD',
          },
        ],
      },
    ],
  },
  {
    id: 'suporte',
    title: 'Suporte',
    href: '/suporte',
    isActive: true,
    permission: ['all'],
    items: [
      {
        id: 'chat',
        title: 'Chat',
        type: 'function',
        href: null,
      },
      {
        id: 'treinamento',
        title: 'Treinamento',
        type: 'external',
        href: 'https://viagenspromo.com/portal-do-agente/treinamentos/',
      },
      {
        id: 'material-de-apoio',
        title: 'Material de Apoio',
        type: 'external',
        href: 'https://book2b.freshdesk.com/support/home',
      },
    ],
  },
];
