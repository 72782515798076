import { tv } from 'tailwind-variants';

const styles = {
  container: tv({
    base: `
      rounded-md
      py-1
      px-2
      bg-[#E6FBF4]
      text-[#007D6D]
      text-xs
      w-[fit-content]
    `,
    variants: {
      color: {
        neutral: `
          bg-neutral-100
          text-neutral-800
        `,
        success: `
          bg-[#E6FBF4]
          text-[#007D6D]
        `,
        danger: `
          bg-[#FFEBE8]
          text-[#A43424]
        `,
      },
    },
  }),
};

/**
 * Badge component to display a badge with a text.
 *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The content to be displayed inside the badge.
 * @param {'neutral' | 'success' | 'danger'} [props.color='success'] - The color of the badge.
 * @returns {JSX.Element} The Badge component.
 */
export function Badge({ children, color = 'success' }) {
  return (
    <div className={styles.container({ color })}>
      <span>{children}</span>
    </div>
  );
}
