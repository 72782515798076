import { useState, useEffect } from 'react';
import { BiSolidEdit, BiTrash } from 'react-icons/bi';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { FiEdit } from 'react-icons/fi';
import { PiPlusCircleFill, PiCaretRightBold } from 'react-icons/pi';
import PageHeader from '@components/page-header';
import api from '@services/api';
import { normalizeCurrencyInput } from '@system/utils';
import { calculateSalesPrice } from '@system/utils/calculateSalesPrice';
import IntlCurrencyInput from '../components/intl-currency-input';
import LoadingAnimation from '../components/LoadingAnimation';
import { PagePath } from '../pages-config';

export default function AdminRegrasTarifarias() {
  const [typeAlert, setTypeAlert] = useState('');
  const [titleAlert, settitleAlert] = useState('');
  const [txtAlert, setTxtAlert] = useState('');
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [listData, seListData] = useState([]);
  const [listTotal, setListTotal] = useState([]);
  const [buscaFiltro, setBuscaFiltro] = useState('');
  const [newData, setNewData] = useState(true);
  const [masterTitle] = useState('Base Regras Tarifárias');
  const [iddBase, setIdBase] = useState('');
  const [nomeBase, setNomeBase] = useState('');
  const [taxaEmbarque, setTaxaEmbarque] = useState(0);
  const [markup, setMarkup] = useState(0);
  const [pcCustoAdt, setPcCustoAdt] = useState(0);
  const [pcCustoChd, setPcCustoChd] = useState(0);
  const [pcCustoInf, setPcCustoInf] = useState(0);
  const [prCustoAdt, setPrCustoAdt] = useState(0);
  const [prCustoChd, setPrCustoChd] = useState(0);
  const [prCustoInf, setPrCustoInf] = useState(0);
  const [exCustoAdt, setExCustoAdt] = useState(0);
  const [exCustoChd, setExCustoChd] = useState(0);
  const [exCustoInf, setExCustoInf] = useState(0);
  const [ecCustoAdt, setEcCustoAdt] = useState(0);
  const [ecCustoChd, setEcCustoChd] = useState(0);
  const [ecCustoInf, setEcCustoInf] = useState(0);
  const [pcVendaAdt, setPcVendaAdt] = useState(0);
  const [pcVendaChd, setPcVendaChd] = useState(0);
  const [pcVendaInf, setPcVendaInf] = useState(0);
  const [prVendaAdt, setPrVendaAdt] = useState(0);
  const [prVendaChd, setPrVendaChd] = useState(0);
  const [prVendaInf, setPrVendaInf] = useState(0);
  const [exVendaAdt, setExVendaAdt] = useState(0);
  const [exVendaChd, setExVendaChd] = useState(0);
  const [exVendaInf, setExVendaInf] = useState(0);
  const [ecVendaAdt, setEcVendaAdt] = useState(0);
  const [ecVendaChd, setEcVendaChd] = useState(0);
  const [ecVendaInf, setEcVendaInf] = useState(0);

  const [deleteId, setDeleteId] = useState('');
  const [deleteNome, setDeleteNome] = useState('');

  const currencyConfig = {
    locale: 'pt-BR',
    formats: {
      number: {
        BRL: {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  useEffect(() => {
    setLoading(true);
    api
      .get('/tariff-rules')
      .then((response) => {
        if (response.data.length > 0) {
          seListData(response.data);
          setListTotal(response.data.length);
        }
      })
      .catch((error) => alert(error));
    setLoading(false);
  }, []);

  function limpaForm() {
    setLoading(true);
    setNewData(true);
    setIdBase('');
    setNomeBase('');
    setTaxaEmbarque(0);
    setMarkup(0);
    setPcCustoAdt(0);
    setPcCustoChd(0);
    setPcCustoInf(0);
    setPrCustoAdt(0);
    setPrCustoChd(0);
    setPrCustoInf(0);
    setExCustoAdt(0);
    setExCustoChd(0);
    setExCustoInf(0);
    setEcCustoAdt(0);
    setEcCustoChd(0);
    setEcCustoInf(0);
    setPcVendaAdt(0);
    setPcVendaChd(0);
    setPcVendaInf(0);
    setPrVendaAdt(0);
    setPrVendaChd(0);
    setPrVendaInf(0);
    setExVendaAdt(0);
    setExVendaChd(0);
    setExVendaInf(0);
    setEcVendaAdt(0);
    setEcVendaChd(0);
    setEcVendaInf(0);
    setTimeout(() => setLoading(false), 1000);
  }

  function validaForm(e) {
    setLoading(true);
    e.preventDefault();

    const formatInputValue = (id) => {
      const inputValue = document.getElementById(id)?.value || '0';
      return normalizeCurrencyInput(inputValue);
    };

    const inputIds = {
      nome: 'nomeBase',
      taxa_embarque: 'taxaEmbarque',
      markup: 'markup',
      copc: ['pcCustoAdt', 'pcCustoChd', 'pcCustoInf'],
      copr: ['prCustoAdt', 'prCustoChd', 'prCustoInf'],
      coex: ['exCustoAdt', 'exCustoChd', 'exCustoInf'],
      coec: ['ecCustoAdt', 'ecCustoChd', 'ecCustoInf'],
      vvpc: ['pcVendaAdt', 'pcVendaChd', 'pcVendaInf'],
      vvpr: ['prVendaAdt', 'prVendaChd', 'prVendaInf'],
      vvex: ['exVendaAdt', 'exVendaChd', 'exVendaInf'],
      vvec: ['ecVendaAdt', 'ecVendaChd', 'ecVendaInf'],
    };

    const mapArrayToFields = (array, prefix) => ({
      [`${prefix}_adt`]: array[0],
      [`${prefix}_chd`]: array[1],
      [`${prefix}_inf`]: array[2],
    });

    const getFormattedValues = () => {
      const formattedValues = {};

      Object.entries(inputIds).forEach(([key, value]) => {
        formattedValues[key] = Array.isArray(value)
          ? value.map((id) => formatInputValue(id))
          : formatInputValue(value);
      });

      return {
        taxa_embarque: formattedValues.taxa_embarque,
        markup: formattedValues.markup,
        ...mapArrayToFields(formattedValues.copc, 'copc'),
        ...mapArrayToFields(formattedValues.copr, 'copr'),
        ...mapArrayToFields(formattedValues.coex, 'coex'),
        ...mapArrayToFields(formattedValues.coec, 'coec'),
        ...mapArrayToFields(formattedValues.vvpc, 'vvpc'),
        ...mapArrayToFields(formattedValues.vvpr, 'vvpr'),
        ...mapArrayToFields(formattedValues.vvex, 'vvex'),
        ...mapArrayToFields(formattedValues.vvec, 'vvec'),
      };
    };

    const valores = getFormattedValues();

    const insertData = async () => {
      try {
        const response = await api.post(`/tariff-rules`, {
          nome: nomeBase.trim(),
          ...valores,
        });

        handleApiResponse(response, 'Cadastrado com sucesso.');
      } catch (error) {
        alert('Erro ao cadastrar: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    const updateData = async () => {
      try {
        const response = await api.put(`/tariff-rules/${iddBase}`, {
          nome: nomeBase.trim(),
          ...valores,
        });

        handleApiResponse(response, 'Atualizado com sucesso.');
      } catch (error) {
        alert('Erro ao atualizar: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    const handleApiResponse = (response, successMessage) => {
      if (response.status === 200 || response.status === 201) {
        setTypeAlert('alert-success');
        settitleAlert('Sucesso!');
        setTxtAlert(successMessage);
        setTimeout(() => setTypeAlert(''), 3000);
        limpaForm();
        listaTable();
      } else {
        setTypeAlert('alert-danger');
        settitleAlert('Erro!');
        setTimeout(() => setTypeAlert(''), 3000);
      }
    };

    if (newData) {
      insertData();
    } else {
      updateData();
    }
  }

  function listaTable() {
    setLoading(true);
    api
      .get('/tariff-rules')
      .then((response) => {
        if (response.data.length > 0) {
          seListData(response.data);
          setLoading(false);
        }
      })
      .catch((error) => alert(error));
  }

  function editaData(
    id,
    nome,
    taxa_embarque,
    markup,
    copc_adt,
    copc_chd,
    copc_inf,
    copr_adt,
    copr_chd,
    copr_inf,
    coex_adt,
    coex_chd,
    coex_inf,
    coec_adt,
    coec_chd,
    coec_inf,
    vvpc_adt,
    vvpc_chd,
    vvpc_inf,
    vvpr_adt,
    vvpr_chd,
    vvpr_inf,
    vvex_adt,
    vvex_chd,
    vvex_inf,
    vvec_adt,
    vvec_chd,
    vvec_inf,
  ) {
    setLoading(true);
    limpaForm();
    setNewData(false);
    setIdBase(id);
    setNomeBase(nome);
    setTaxaEmbarque(taxa_embarque);
    setMarkup(markup.replace(',', '.'));
    setPcCustoAdt(copc_adt);
    setPcCustoChd(copc_chd);
    setPcCustoInf(copc_inf);
    setPrCustoAdt(copr_adt);
    setPrCustoChd(copr_chd);
    setPrCustoInf(copr_inf);
    setExCustoAdt(coex_adt);
    setExCustoChd(coex_chd);
    setExCustoInf(coex_inf);
    setEcCustoAdt(coec_adt);
    setEcCustoChd(coec_chd);
    setEcCustoInf(coec_inf);
    setPcVendaAdt(vvpc_adt);
    setPcVendaChd(vvpc_chd);
    setPcVendaInf(vvpc_inf);
    setPrVendaAdt(vvpr_adt);
    setPrVendaChd(vvpr_chd);
    setPrVendaInf(vvpr_inf);
    setExVendaAdt(vvex_adt);
    setExVendaChd(vvex_chd);
    setExVendaInf(vvex_inf);
    setEcVendaAdt(vvec_adt);
    setEcVendaChd(vvec_chd);
    setEcVendaInf(vvec_inf);
    setTimeout(() => setLoading(false), 1000);
  }

  function listSearch() {
    setLoading(true);
    setTypeAlert('');
    api
      .post('/tariff-rules/filter', { nome: buscaFiltro })
      .then((response) => {
        if (response.data.length > 0) {
          seListData(response.data);
          setLoading(false);
        } else {
          setTypeAlert('alert-danger');
          settitleAlert('Dados não encontrados!');
          setTimeout(() => setTypeAlert(''), 3000);
          setLoading(false);
        }
      })
      .catch((error) => alert(error));
  }
  function changeBuscaFiltro(e) {
    setBuscaFiltro(e);
    setTypeAlert('');
    if (e.trim().length == 0) {
      setLoading(true);
      api
        .get('/tariff-rules')
        .then((response) => {
          if (response.data.length > 0) {
            seListData(response.data);
            setListTotal(response.data.length);
            setLoading(false);
          }
        })
        .catch((error) => alert(error));
    }
  }
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      listSearch();
    }
  };

  function modalDelete(id, nome) {
    setOpenModal(true);
    setDeleteId(id);
    setDeleteNome(nome);
  }

  function deleteBase(id) {
    setOpenModal(false);
    setLoading(true);
    api
      .delete(`/tariff-rules/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          limpaForm();
          listaTable();
        }
      })
      .catch((error) => {
        alert(error);
        setLoading(false);
      });
  }

  function formatPrice(x, e) {
    let v_markup = markup;
    let v_taxaEmbarque = document
      .getElementById('taxaEmbarque')
      .value.replace('R$', '')
      .replace('.', '')
      .replace(',', '.')
      .replace(' ', '.')
      .trim();
    let v_pcCustoAdt = document
      .getElementById('pcCustoAdt')
      .value.replace('R$', '')
      .replace('.', '')
      .replace(',', '.')
      .replace(' ', '.')
      .trim();
    let v_pcCustoChd = document
      .getElementById('pcCustoChd')
      .value.replace('R$', '')
      .replace('.', '')
      .replace(',', '.')
      .replace(' ', '.')
      .trim();
    let v_pcCustoInf = document
      .getElementById('pcCustoInf')
      .value.replace('R$', '')
      .replace('.', '')
      .replace(',', '.')
      .replace(' ', '.')
      .trim();

    let v_prCustoAdt = document
      .getElementById('prCustoAdt')
      .value.replace('R$', '')
      .replace('.', '')
      .replace(',', '.')
      .replace(' ', '.')
      .trim();
    let v_prCustoChd = document
      .getElementById('prCustoChd')
      .value.replace('R$', '')
      .replace('.', '')
      .replace(',', '.')
      .replace(' ', '.')
      .trim();
    let v_prCustoInf = document
      .getElementById('prCustoInf')
      .value.replace('R$', '')
      .replace('.', '')
      .replace(',', '.')
      .replace(' ', '.')
      .trim();

    let v_exCustoAdt = document
      .getElementById('exCustoAdt')
      .value.replace('R$', '')
      .replace('.', '')
      .replace(',', '.')
      .replace(' ', '.')
      .trim();
    let v_exCustoChd = document
      .getElementById('exCustoChd')
      .value.replace('R$', '')
      .replace('.', '')
      .replace(',', '.')
      .replace(' ', '.')
      .trim();
    let v_exCustoInf = document
      .getElementById('exCustoInf')
      .value.replace('R$', '')
      .replace('.', '')
      .replace(',', '.')
      .replace(' ', '.')
      .trim();

    let v_ecCustoAdt = document
      .getElementById('ecCustoAdt')
      .value.replace('R$', '')
      .replace('.', '')
      .replace(',', '.')
      .replace(' ', '.')
      .trim();
    let v_ecCustoChd = document
      .getElementById('ecCustoChd')
      .value.replace('R$', '')
      .replace('.', '')
      .replace(',', '.')
      .replace(' ', '.')
      .trim();
    let v_ecCustoInf = document
      .getElementById('ecCustoInf')
      .value.replace('R$', '')
      .replace('.', '')
      .replace(',', '.')
      .replace(' ', '.')
      .trim();

    if (x == 'markup') {
      setMarkup(e), (v_markup = e);
    } else if (x == 'taxaEmbarque') {
      e = e.replace('R$', '').replace(',', '.').trim();
      e = e.replace(/\D+/g, '');
      e = Number(e) / 100;
      setTaxaEmbarque(e);
      v_taxaEmbarque = e;
    } else {
      e = e.replace('R$', '').replace(',', '.').trim();
      e = e.replace(/\D+/g, '');
      e = Number(e) / 100;

      if (x == 'pcCustoAdt') {
        setPcCustoAdt(e);
        v_pcCustoAdt = e;
      }
      if (x == 'pcCustoChd') {
        setPcCustoChd(e);
        v_pcCustoChd = e;
      }
      if (x == 'pcCustoInf') {
        setPcCustoInf(e);
        v_pcCustoInf = e;
      }

      if (x == 'prCustoAdt') {
        setPrCustoAdt(e);
        v_prCustoAdt = e;
      }
      if (x == 'prCustoChd') {
        setPrCustoChd(e);
        v_prCustoChd = e;
      }
      if (x == 'prCustoInf') {
        setPrCustoInf(e);
        v_prCustoInf = e;
      }

      if (x == 'exCustoAdt') {
        setExCustoAdt(e);
        v_exCustoAdt = e;
      }
      if (x == 'exCustoChd') {
        setExCustoChd(e);
        v_exCustoChd = e;
      }
      if (x == 'exCustoInf') {
        setExCustoInf(e);
        v_exCustoInf = e;
      }

      if (x == 'ecCustoAdt') {
        setEcCustoAdt(e);
        v_ecCustoAdt = e;
      }
      if (x == 'ecCustoChd') {
        setEcCustoChd(e);
        v_ecCustoChd = e;
      }
      if (x == 'ecCustoInf') {
        setEcCustoInf(e);
        v_ecCustoInf = e;
      }
    }

    setPcVendaAdt(
      calculateSalesPrice(
        Number(v_pcCustoAdt),
        Number(v_markup),
        Number(v_taxaEmbarque),
      ),
    );
    setPcVendaChd(
      calculateSalesPrice(
        Number(v_pcCustoChd),
        Number(v_markup),
        Number(v_taxaEmbarque),
      ),
    );
    setPcVendaInf(
      calculateSalesPrice(
        Number(v_pcCustoInf),
        Number(v_markup),
        Number(v_taxaEmbarque),
      ),
    );

    setPrVendaAdt(
      calculateSalesPrice(
        Number(v_prCustoAdt),
        Number(v_markup),
        Number(v_taxaEmbarque),
      ),
    );
    setPrVendaChd(
      calculateSalesPrice(
        Number(v_prCustoChd),
        Number(v_markup),
        Number(v_taxaEmbarque),
      ),
    );
    setPrVendaInf(
      calculateSalesPrice(
        Number(v_prCustoInf),
        Number(v_markup),
        Number(v_taxaEmbarque),
      ),
    );

    setExVendaAdt(
      calculateSalesPrice(
        Number(v_exCustoAdt),
        Number(v_markup),
        Number(v_taxaEmbarque),
      ),
    );
    setExVendaChd(
      calculateSalesPrice(
        Number(v_exCustoChd),
        Number(v_markup),
        Number(v_taxaEmbarque),
      ),
    );
    setExVendaInf(
      calculateSalesPrice(
        Number(v_exCustoInf),
        Number(v_markup),
        Number(v_taxaEmbarque),
      ),
    );

    setEcVendaAdt(
      calculateSalesPrice(
        Number(v_ecCustoAdt),
        Number(v_markup),
        Number(v_taxaEmbarque),
      ),
    );
    setEcVendaChd(
      calculateSalesPrice(
        Number(v_ecCustoChd),
        Number(v_markup),
        Number(v_taxaEmbarque),
      ),
    );
    setEcVendaInf(
      calculateSalesPrice(
        Number(v_ecCustoInf),
        Number(v_markup),
        Number(v_taxaEmbarque),
      ),
    );
  }

  return (
    <>
      {loading && <LoadingAnimation />}

      {/* Modal */}
      <div className={openModal ? 'bg_loading' : 'hide'}>
        <div className="globalModal modalDelete text-center">
          <span className="closeModal" onClick={() => setOpenModal(false)}>
            ╳
          </span>

          <div className="row mb-3 mt-5">
            <div className="col-12">
              <h4>Confirma Deletar Base de Regra Tarifária?</h4>
              <h3>
                {deleteId} - {deleteNome}
              </h3>
            </div>
            <div className="col-12 mt-4">
              <button onClick={() => deleteBase(deleteId)}>Deletar</button>
            </div>
          </div>
        </div>
      </div>

      {/* Alert */}
      <div className={typeAlert ? 'alert ' + typeAlert : 'hide'} role="alert">
        <h4 className={titleAlert ? '' : 'hide'}>{titleAlert}</h4>
        {txtAlert}
      </div>

      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Configurações', link: PagePath.Admin },
          {
            label: 'Aéreo - Regras Tarifárias',
            link: PagePath.AdminRegrasTarifarias,
          },
        ]}
        title="Cadastro de base tarifária"
      />

      <div className="row contentAll contentLight">
        <form onSubmit={(e) => validaForm(e)}>
          <div className="row ">
            <div className="col-7">
              <label className="mt-1">Base tarifária</label>
              <input
                type="text"
                placeholder="Digite o nome da Base tarifária"
                value={nomeBase}
                onChange={(e) => setNomeBase(e.target.value)}
                required
              ></input>
            </div>

            <div className="col-3 ">
              <label className="mt-1">Taxa de Embarque</label>
              <IntlCurrencyInput
                currency="BRL"
                id="taxaEmbarque"
                config={currencyConfig}
                className="currency"
                value={taxaEmbarque}
                onChange={(e) => formatPrice('taxaEmbarque', e.target.value)}
              />
            </div>
            <div className="col-2">
              <label className="mt-1">Markup %</label>
              <input
                type="number"
                step="0.01"
                placeholder="%"
                id="markup"
                value={markup}
                onChange={(e) => formatPrice('markup', e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <hr className="mb-5 mt-5" />
            </div>
          </div>

          <div className="row">
            <div className="col-6 ">
              <div className="col-12 mb-3">
                <h4 className="mb-0">Custo Operacional</h4>
                <small>Preço em reais por pessoa.</small>
              </div>

              <div className="contentEpecial">
                <div className="row borderBottom">
                  <div className="col-3 mob2 mb-3">
                    <b>CLASSE</b>
                    <br />
                    <small>&nbsp;</small>
                  </div>
                  <div className="col-3 mob2 mb-3 text-center">
                    <b>ADT</b>
                    <br />
                    <small>(12+)</small>
                  </div>
                  <div className="col-3 mob2 mb-3 text-center">
                    <b>CHD</b>
                    <br />
                    <small>(2 a 11 anos)</small>
                  </div>
                  <div className="col-3 mob2 mb-3 text-center">
                    <b>INF</b>
                    <br />
                    <small>(0 a 23 meses)</small>
                  </div>
                </div>
                <div className="row mt-3 pb-2">
                  <div className="col-3 classAlignMiddle">
                    <div className="classAlignMiddle">Classe 1</div>
                  </div>
                  <div className="col-3 colTarifa">
                    <label className="desk mt-1">Custo ADT</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="pcCustoAdt"
                      config={currencyConfig}
                      className="currency"
                      value={pcCustoAdt}
                      onChange={(e) =>
                        formatPrice('pcCustoAdt', e.target.value)
                      }
                    />
                  </div>
                  <div className="col-3 colTarifa">
                    <label className="desk mt-1">Custo CHD</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="pcCustoChd"
                      config={currencyConfig}
                      className="currency"
                      value={pcCustoChd}
                      onChange={(e) =>
                        formatPrice('pcCustoChd', e.target.value)
                      }
                    />
                  </div>
                  <div className="col-3 colTarifa">
                    <label className="desk mt-1">Custo INF</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="pcCustoInf"
                      config={currencyConfig}
                      className="currency"
                      value={pcCustoInf}
                      onChange={(e) =>
                        formatPrice('pcCustoInf', e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="row mt-3 pb-2">
                  <div className="col-3 classAlignMiddle">
                    <div className="classAlignMiddle">Classe 2</div>
                  </div>
                  <div className="col-3 colTarifa">
                    <label className="desk mt-1">Custo ADT</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="prCustoAdt"
                      config={currencyConfig}
                      className="currency"
                      value={prCustoAdt}
                      onChange={(e) =>
                        formatPrice('prCustoAdt', e.target.value)
                      }
                    />
                  </div>
                  <div className="col-3 colTarifa">
                    <label className="desk mt-1">Custo CHD</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="prCustoChd"
                      config={currencyConfig}
                      className="currency"
                      value={prCustoChd}
                      onChange={(e) =>
                        formatPrice('prCustoChd', e.target.value)
                      }
                    />
                  </div>
                  <div className="col-3 colTarifa">
                    <label className="desk mt-1">Custo INF</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="prCustoInf"
                      config={currencyConfig}
                      className="currency"
                      value={prCustoInf}
                      onChange={(e) =>
                        formatPrice('prCustoInf', e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="row mt-3 pb-2">
                  <div className="col-3 classAlignMiddle">
                    <div className="classAlignMiddle">Classe 3</div>
                  </div>
                  <div className="col-3 colTarifa">
                    <label className="desk mt-1">Custo ADT</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="exCustoAdt"
                      config={currencyConfig}
                      className="currency"
                      value={exCustoAdt}
                      onChange={(e) =>
                        formatPrice('exCustoAdt', e.target.value)
                      }
                    />
                  </div>
                  <div className="col-3 colTarifa">
                    <label className="desk mt-1">Custo CHD</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="exCustoChd"
                      config={currencyConfig}
                      className="currency"
                      value={exCustoChd}
                      onChange={(e) =>
                        formatPrice('exCustoChd', e.target.value)
                      }
                    />
                  </div>
                  <div className="col-3 colTarifa">
                    <label className="desk mt-1">Custo INF</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="exCustoInf"
                      config={currencyConfig}
                      className="currency"
                      value={exCustoInf}
                      onChange={(e) =>
                        formatPrice('exCustoInf', e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-3 classAlignMiddle">
                    <div className="classAlignMiddle">Classe 4</div>
                  </div>
                  <div className="col-3 colTarifa">
                    <label className="desk mt-1">Custo ADT</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="ecCustoAdt"
                      config={currencyConfig}
                      className="currency"
                      value={ecCustoAdt}
                      onChange={(e) =>
                        formatPrice('ecCustoAdt', e.target.value)
                      }
                    />
                  </div>
                  <div className="col-3 colTarifa">
                    <label className="desk mt-1">Custo CHD</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="ecCustoChd"
                      config={currencyConfig}
                      className="currency"
                      value={ecCustoChd}
                      onChange={(e) =>
                        formatPrice('ecCustoChd', e.target.value)
                      }
                    />
                  </div>
                  <div className="col-3 colTarifa pb-3">
                    <label className="desk mt-1">Custo INF</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="ecCustoInf"
                      config={currencyConfig}
                      className="currency"
                      value={ecCustoInf}
                      onChange={(e) =>
                        formatPrice('ecCustoInf', e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6 ">
              <div className="col-12 mb-3">
                <h4 className="mb-0">Valor de Venda</h4>
                <small>Preço em reais por pessoa.</small>
              </div>

              <div className="contentEpecial">
                <div className="row borderBottom">
                  <div className="col-3 mob2 mb-3">
                    <b>CLASSE</b>
                    <br />
                    <small>&nbsp;</small>
                  </div>
                  <div className="col-3 mob2 mb-3 text-center">
                    <b>ADT</b>
                    <br />
                    <small>(12+)</small>
                  </div>
                  <div className="col-3 mob2 mb-3 text-center">
                    <b>CHD</b>
                    <br />
                    <small>(2 a 11 anos)</small>
                  </div>
                  <div className="col-3 mob2 mb-3 text-center">
                    <b>INF</b>
                    <br />
                    <small>(0 a 23 meses)</small>
                  </div>
                </div>
                <div className="row mt-3 pb-2">
                  <div className="col-3 classAlignMiddle">
                    <div className="classAlignMiddle">Classe 1</div>
                  </div>
                  <div className="col-3 colTarifa disable">
                    <label className="desk mt-1">ADT</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="pcVendaAdt"
                      config={currencyConfig}
                      className="currency"
                      value={pcVendaAdt}
                      onChange={(e) => setPcVendaAdt(e.target.value)}
                    />
                  </div>
                  <div className="col-3 colTarifa disable">
                    <label className="desk mt-1">CHD</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="pcVendaChd"
                      config={currencyConfig}
                      className="currency"
                      value={pcVendaChd}
                      onChange={(e) => setPcVendaChd(e.target.value)}
                    />
                  </div>

                  <div className="col-3 colTarifa disable">
                    <label className="desk mt-1">INF</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="pcVendaInf"
                      config={currencyConfig}
                      className="currency"
                      value={pcVendaInf}
                      onChange={(e) => setPcVendaInf(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-3 pb-2">
                  <div className="col-3 classAlignMiddle">
                    <div className="classAlignMiddle">Classe 2</div>
                  </div>
                  <div className="col-3 colTarifa disable">
                    <label className="desk mt-1">ADT</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="prVendaAdt"
                      config={currencyConfig}
                      className="currency"
                      value={prVendaAdt}
                      onChange={(e) => setPrVendaAdt(e.target.value)}
                    />
                  </div>
                  <div className="col-3 colTarifa disable">
                    <label className="desk mt-1">CHD</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="prVendaChd"
                      config={currencyConfig}
                      className="currency"
                      value={prVendaChd}
                      onChange={(e) => setPrVendaChd(e.target.value)}
                    />
                  </div>
                  <div className="col-3 colTarifa disable">
                    <label className="desk mt-1">INF</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="prVendaInf"
                      config={currencyConfig}
                      className="currency"
                      value={prVendaInf}
                      onChange={(e) => setPrVendaInf(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-3 pb-2">
                  <div className="col-3 classAlignMiddle">
                    <div className="classAlignMiddle">Classe 3</div>
                  </div>
                  <div className="col-3 colTarifa disable">
                    <label className="desk mt-1">ADT</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="exVendaAdt"
                      config={currencyConfig}
                      className="currency"
                      value={exVendaAdt}
                      onChange={(e) => setExVendaAdt(e.target.value)}
                    />
                  </div>
                  <div className="col-3 colTarifa disable">
                    <label className="desk mt-1">CHD</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="exVendaChd"
                      config={currencyConfig}
                      className="currency"
                      value={exVendaChd}
                      onChange={(e) => setExVendaChd(e.target.value)}
                    />
                  </div>

                  <div className="col-3 colTarifa disable">
                    <label className="desk mt-1">INF</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="exVendaInf"
                      config={currencyConfig}
                      className="currency"
                      value={exVendaInf}
                      onChange={(e) => setExVendaInf(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-3 classAlignMiddle">
                    <div className="classAlignMiddle">Classe 4</div>
                  </div>
                  <div className="col-3 colTarifa disable">
                    <label className="desk mt-1">ADT</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="ecVendaAdt"
                      config={currencyConfig}
                      className="currency"
                      value={ecVendaAdt}
                      onChange={(e) => setEcVendaAdt(e.target.value)}
                    />
                  </div>
                  <div className="col-3 colTarifa disable">
                    <label className="desk mt-1">CHD</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="ecVendaChd"
                      config={currencyConfig}
                      className="currency"
                      value={ecVendaChd}
                      onChange={(e) => setEcVendaChd(e.target.value)}
                    />
                  </div>
                  <div className="col-3 colTarifa disable pb-3">
                    <label className="desk mt-1">INF</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      id="ecVendaInf"
                      config={currencyConfig}
                      className="currency"
                      value={ecVendaInf}
                      onChange={(e) => setEcVendaInf(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col"></div>
            <div className="col-3">
              <button type="submit">
                <span className={loading ? 'hide' : ''}>
                  <span className={newData ? '' : 'hide'}>
                    <PiCaretRightBold /> Salvar
                  </span>
                  <span className={!newData ? '' : 'hide'}>
                    <FiEdit /> Alterar
                  </span>
                </span>
                <span className={loading ? 'loader' : 'hide'}></span>
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className="contentAll mt-3">
        <div className="row lineButtons">
          <div className="col pl-0">
            <h4>{masterTitle}</h4>
          </div>

          <div className="col btnTable">
            <div className="buscaFiltro">
              <input
                type="text"
                className="inputSearch"
                placeholder="Procurar por"
                value={buscaFiltro}
                onChange={(e) => changeBuscaFiltro(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <FaMagnifyingGlass onClick={() => listSearch()} />
            </div>
            <div className="rightBtn">
              <div>{listData.length}</div>
              <div>de</div>
              <div className="listTotal">{listTotal}</div>
              <button
                type="button"
                className="btn btnNew"
                onClick={() => limpaForm()}
              >
                Novo <PiPlusCircleFill />
              </button>
            </div>
          </div>
        </div>
        <div className="text-sm">
          <table className="tblDefault">
            <thead>
              <tr>
                <th scope="col">NOME</th>
                <th scope="col">TAXA EMBARQUE</th>
                <th scope="col">MARKUP</th>
                <th scope="col" width="40px"></th>
                <th scope="col" width="40px"></th>
              </tr>
            </thead>
            <tbody>
              {listData.map((data, index) => (
                <tr
                  key={index}
                  onClick={() =>
                    editaData(
                      data.id,
                      data.nome,
                      data.taxa_embarque,
                      data.markup,
                      data.copc_adt,
                      data.copc_chd,
                      data.copc_inf,
                      data.copr_adt,
                      data.copr_chd,
                      data.copr_inf,
                      data.coex_adt,
                      data.coex_chd,
                      data.coex_inf,
                      data.coec_adt,
                      data.coec_chd,
                      data.coec_inf,
                      data.vvpc_adt,
                      data.vvpc_chd,
                      data.vvpc_inf,
                      data.vvpr_adt,
                      data.vvpr_chd,
                      data.vvpr_inf,
                      data.vvex_adt,
                      data.vvex_chd,
                      data.vvex_inf,
                      data.vvec_adt,
                      data.vvec_chd,
                      data.vvec_inf,
                    )
                  }
                  className={iddBase == data.id ? 'trSelect' : ''}
                >
                  <td>{data.nome}</td>
                  <td>R$ {data.taxa_embarque}</td>
                  <td>{data.markup}%</td>
                  <td>
                    <BiSolidEdit />
                  </td>
                  <td>
                    <BiTrash onClick={() => modalDelete(data.id, data.nome)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
