import { useCallback, useEffect, useMemo, useState } from 'react';
import { GoCheckCircle, GoXCircleFill } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import { ButtonV2 } from '@components/new-components/button-v2';
import api from '@services/api';
import { formatCurrency } from '@system/utils';
import { Modal } from '.';
import { PagePath } from '../../pages-config';

const supportIframeZoom = {
  zoom: 0.65,
  '-moz-transform': 'scale(0.65)',
  '-moz-transform-origin': '0 0',
};

export function ModalReservationPayment({
  isOpen,
  onClose,
  bookings,
  setPaymentUrl,
  paymentUrl,
}) {
  const [isLoadingApi, setIsLoadingApi] = useState(false);
  const [state, setState] = useState('initial');

  const navigate = useNavigate();

  const totalBooking = useMemo(() => {
    return bookings
      ?.map((b) => b.totalAmount)
      ?.reduce((prev, cur) => prev + cur, 0);
  }, [bookings]);

  const totalFeeBooking = useMemo(() => {
    return bookings
      ?.map(
        (b) =>
          b.totalAmount -
          b.totalAmount * (1 - getFeePayment(b.paymentEstimatedDate)),
      )
      ?.reduce((prev, cur) => prev + cur, 0);
  }, [bookings]);

  function getFeePayment(paymentEstimatedDate) {
    if (!paymentEstimatedDate) {
      return 0;
    }
    return new Date() < new Date(paymentEstimatedDate) ? 0.1 : 0;
  }

  const parseBookings = useCallback((bookings) => {
    return bookings.map((booking) => {
      const fee =
        booking.totalAmount * getFeePayment(booking.paymentEstimatedDate);
      const amountToReceive =
        booking.totalAmount * (1 - getFeePayment(booking.paymentEstimatedDate));

      return {
        id: booking.id,
        fee: parseFloat(fee.toFixed(2)),
        booking_amount: parseFloat(booking.totalAmount.toFixed(2)),
        amount_to_receive: parseFloat(amountToReceive.toFixed(2)),
      };
    });
  }, []);

  const handlePayment = useCallback(
    async (signal) => {
      if (paymentUrl) {
        return;
      }
      setIsLoadingApi(true);
      setState('initial');
      try {
        const parseBooking = {
          total: totalBooking - totalFeeBooking,
          bookings: parseBookings(bookings),
        };
        const { data } = await api.post('/receivable-requests', parseBooking, {
          signal,
        });
        const { payment_url: PaymentUrl } = data;
        setPaymentUrl(PaymentUrl);
      } catch (error) {
        setState('error');
      } finally {
        setIsLoadingApi(false);
      }
    },
    [
      bookings,
      parseBookings,
      totalBooking,
      totalFeeBooking,
      setPaymentUrl,
      paymentUrl,
    ],
  );

  const retryPayment = useCallback(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    handlePayment(signal);
  }, [handlePayment]);

  const handleClose = useCallback(() => {
    if (isLoadingApi) {
      return;
    }
    onClose();
  }, [isLoadingApi, onClose]);

  const successCloseModal = useCallback(() => {
    onClose();
    navigate(PagePath.FinanceiroRecebiveis);
  }, [navigate, onClose]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (!isOpen) {
      return;
    }

    handlePayment(signal);

    return () => {
      controller.abort();
    };
  }, [handlePayment, isOpen]);

  const handleMessage = useCallback((event) => {
    if (event.data === 'pagamentoConcluido') {
      setState('success');
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  const initialModalScreen = useMemo(() => {
    return (
      <>
        <Modal.Header title="Pagamento" handleClose={handleClose} />
        <p>
          O valor de{' '}
          <span className="font-semibold">
            {formatCurrency(totalBooking - totalFeeBooking, true)}
          </span>{' '}
          será pago via fornecedor Faturepag. Confira os dados e selecione
          ”Vender” para realizar a solicitação. O pagamento será feito via
          cartão de crédito, conforme prazos pré definidos.
        </p>
        {isLoadingApi && (
          <div className="mt-4 flex justify-center">
            <p>Carregando ...</p>
          </div>
        )}
        {paymentUrl && (
          <div className="h-[460px] w-[100%]">
            <iframe
              src={paymentUrl}
              title="payment"
              width="100%"
              height="100%"
              style={supportIframeZoom}
            />
          </div>
        )}
      </>
    );
  }, [handleClose, isLoadingApi, paymentUrl, totalBooking, totalFeeBooking]);

  const errorModalScreen = useMemo(() => {
    return (
      <>
        <div className="flex flex-col items-center justify-center p-7">
          <div className="text-center">
            <div className="text-center">
              <GoXCircleFill size={40} className="text-error-500" />
            </div>
            <h3 className="mt-3 text-center text-heading-1 text-error-500">
              Ops, ocorreu um problema durante a transação
            </h3>
            <p className="mt-4">Aguarde uns minutos e tente novamente</p>
          </div>
          <div className="mt-8 text-center">
            <ButtonV2 className="!px-9 !py-3" onClick={retryPayment}>
              <ButtonV2.Text className="!text-heading-3">
                Tentar novamente
              </ButtonV2.Text>
            </ButtonV2>
          </div>
        </div>
      </>
    );
  }, [retryPayment]);

  const successModalScreen = useMemo(() => {
    return (
      <div className="flex max-w-[640px] flex-col items-center justify-center p-7">
        <div className="text-center">
          <div className="text-center">
            <GoCheckCircle size={40} className="text-success-500" />
          </div>
          <h3 className="mt-3 text-center text-heading-1 text-success-500">
            O pagamento foi solicitado!
          </h3>
          <p className="mt-4">
            O valor de{' '}
            <span className="font-semibold">
              {formatCurrency(totalBooking - totalFeeBooking, true)}
            </span>{' '}
            será pago de acordo com os prazos de recebimento do hotel.
          </p>
        </div>
        <div className="mt-8 text-center">
          <ButtonV2
            ghost
            type="button"
            className="!px-4 !py-3"
            onClick={successCloseModal}
          >
            <ButtonV2.Text>Fechar</ButtonV2.Text>
          </ButtonV2>
        </div>
      </div>
    );
  }, [successCloseModal, totalBooking, totalFeeBooking]);

  const states = {
    initial: initialModalScreen,
    error: errorModalScreen,
    success: successModalScreen,
  };

  return (
    <Modal
      open={isOpen}
      handleClose={() => {
        if (state === 'success') {
          successCloseModal();
          return;
        }
        handleClose();
      }}
      size="big"
    >
      {states[state]}
    </Modal>
  );
}
