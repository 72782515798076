import Logo from '@components/Logo';

export function ActivateAccountHeader() {
  return (
    <div className="flex justify-center bg-white p-4">
      <div className="max-h-[24px]">
        <Logo />
      </div>
    </div>
  );
}
