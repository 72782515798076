import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { tv } from 'tailwind-variants';
import * as yup from 'yup';
import { Button } from '@components/button';
import { ModalPerfilConfirm } from '@components/modal/ModalPerfilConfirm';
import { Form } from '@components/new-components/form';
import { InputV2 } from '@components/new-components/input-v2';
import PageHeader from '@components/page-header';
import api from '@services/api';
import { cpfValidator, formatCpf } from '@system/validators';
import Globals from '../components/Globals';
import { PagePath } from '../pages-config';
import AnalyticsService from '@services/analytics/analytics';
import events from '@services/analytics/events';

function MeusDados() {
  const [typeAlert, setTypeAlert] = useState('');
  const [titleAlert, settitleAlert] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    numberOrSymbol: false,
  });

  const styles = {
    button: tv({
      base: 'font-semibold',
      variants: {
        loading: {
          true: 'hidden',
        },
      },
    }),
    loader: tv({
      base: [
        'relative top-[-20px] h-4 w-4 rounded-full text-[8px] text-white',
        'before:absolute before:left-[-3.5em] before:top-0 before:h-4 before:w-4 before:rounded-full before:content-[""]',
        'after:absolute after:left-[3.5em] after:top-0 after:h-4 after:w-4 before:rounded-full before:content-[""]',
      ],
      variants: {
        loading: {
          false: 'hidden',
          true: 'vgp-spinner',
        },
      },
    }),
  };

  const schemaValidation = yup.object().shape({
    firstName: yup.string().required('Nome é obrigatório'),
    familyName: yup.string().required('Sobrenome é obrigatório'),
    cpf: yup
      .string()
      .required('CPF é obrigatório')
      .test(
        'is-valid-cpf',
        'CPF deve conter exatamente 11 números',
        (value) => {
          if (!value) return false;
          const numericValue = value.replace(/\D/g, '');
          return numericValue.length === 11;
        },
      )
      .test('is-valid-cpf-format', 'CPF inválido', (value) => {
        if (!value) return false;
        const numericValue = value.replace(/\D/g, '');
        return cpfValidator(numericValue);
      }),

    email: yup
      .string()
      .email('Digite um e-mail válido')
      .required('E-mail é obrigatório'),

    senhaAtual: yup.string(),

    novaSenha: yup.string().when('senhaAtual', (senhaAtual, schema) => {
      if (
        Array.isArray(senhaAtual) &&
        senhaAtual.some((senha) => senha.trim().length > 0)
      ) {
        return schema
          .required('Nova senha é obrigatória')
          .min(8, 'Mínimo de 8 caracteres')
          .matches(/[A-Z]/, 'Deve conter pelo menos uma letra maiúscula')
          .matches(/[a-z]/, 'Deve conter pelo menos uma letra minúscula')
          .matches(
            /[0-9!@#$%^&*(),.?":{}|<>]/,
            'Deve conter pelo menos um número ou símbolo',
          );
      }
      return schema.notRequired();
    }),

    confirmarNovaSenha: yup.string().when('novaSenha', (novaSenha, schema) => {
      if (
        Array.isArray(novaSenha) &&
        novaSenha.some((senha) => senha.trim().length > 0)
      ) {
        return schema
          .required('Confirmação da nova senha é obrigatória')
          .oneOf([yup.ref('novaSenha')], 'As senhas não coincidem')
          .test(
            'senhaAtual-required',
            'Senha atual é obrigatória quando nova senha é preenchida',
            function () {
              const { senhaAtual } = this.parent;
              return senhaAtual && senhaAtual.length > 0;
            },
          );
      }
      return schema.notRequired();
    }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaValidation),
    mode: 'all',
  });

  useEffect(() => {
    AnalyticsService.trackEvent(events.MY_PROFILE_PAGE_VIEWED);

    api
      .get(`/users/${Globals.userId}`)
      .then((response) => {
        if (response.data) {
          const user = response.data;
          setValue('firstName', user.firstName);
          setValue('familyName', user.familyName);
          setValue('email', user.email);
          setValue('cpf', formatCpf(user.cpf));
        } else {
          setTypeAlert('alert-danger');
          settitleAlert('Usuário não encontrado!');
          setTimeout(() => setTypeAlert(''), 3000);
        }

        setTimeout(() => {
          setValue('senhaAtual', '');
        }, 100);
      })
      .catch((error) => alert(error));
  }, [setValue]);

  const novaSenha = watch('novaSenha', '');
  useEffect(() => {
    setPasswordValidation({
      length: novaSenha.length >= 8,
      uppercase: /[A-Z]/.test(novaSenha),
      lowercase: /[a-z]/.test(novaSenha),
      numberOrSymbol: /[0-9!@#$%^&*(),.?":{}|<>]/.test(novaSenha),
    });
  }, [novaSenha]);

  async function onSubmit(data) {
    AnalyticsService.trackEvent(events.MY_PROFILE_SAVE_BUTTON_CLICKED);

    setLoading(true);
    try {
      const updated = await api.patch(`/users/${Globals.userId}`, {
        email: data.email,
        first_name: data.firstName,
        family_name: data.familyName,
        cpf: data.cpf,
        ...(data.senhaAtual.trim()
          ? { actualPassword: data.senhaAtual, password: data.novaSenha }
          : {}),
      });

      if (updated.status === 204) {
        setIsModalOpen(true);

        AnalyticsService.trackEvent(
          events.MY_PROFILE_SUCCESS_MODAL_PAGE_VIEWED,
        );
      }
    } catch (e) {
      setTypeAlert('alert-danger');
      settitleAlert(e.message);
      setTimeout(() => setTypeAlert(''), 3000);
    }

    setLoading(false);
  }

  return (
    <>
      <div className={typeAlert ? 'alert ' + typeAlert : 'hide'} role="alert">
        <h4 className={titleAlert ? '' : 'hide'}>{titleAlert}</h4>
      </div>

      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Meus perfil', link: PagePath.MeusDados },
        ]}
        title="Meus perfil"
      />
      <Form className="mt-[5px] text-center" onSubmit={handleSubmit(onSubmit)}>
        {/* Wrapper: Dados Pessoais */}
        <div className="shadow mb-8 rounded-lg bg-white p-4">
          <h2 className="mb-4 border-0 border-b border-solid border-neutral-300 pb-2 text-left text-lg font-semibold">
            Dados pessoais
          </h2>
          <div className="grid grid-cols-2 gap-4">
            <Form.Group>
              <Form.Label>Nome</Form.Label>
              <InputV2
                type="text"
                placeholder="Digite seu nome"
                placeholderStyle="normal"
                {...register('firstName')}
                hasError={!!errors?.firstName}
              />
              <Form.Message error={errors?.firstName} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Sobrenome</Form.Label>
              <InputV2
                type="text"
                placeholder="Digite seu sobrenome"
                placeholderStyle="normal"
                {...register('familyName')}
                hasError={!!errors?.familyName}
              />
              <Form.Message error={errors?.familyName} />
            </Form.Group>
            <Form.Group>
              <Form.Label>CPF</Form.Label>
              <InputV2
                type="text"
                placeholder="Digite seu CPF"
                placeholderStyle="normal"
                maxLength={14}
                value={watch('cpf', '')}
                {...register('cpf', {
                  required: 'O CPF é obrigatório',
                  onChange: (e) => {
                    const formattedValue = formatCpf(e.target.value);
                    setValue('cpf', formattedValue, { shouldValidate: true });
                  },
                })}
                hasError={!!errors?.cpf}
              />

              <Form.Message error={errors?.cpf} />
            </Form.Group>
            <Form.Group>
              <Form.Label>E-mail</Form.Label>
              <InputV2 type="email" disabled {...register('email')} />
            </Form.Group>
          </div>
        </div>

        {/* Wrapper: Segurança */}
        <div className="shadow rounded-lg bg-white p-4">
          <h2 className="mb-4 border-0 border-b border-solid border-neutral-300 pb-2 text-left text-lg font-semibold">
            Segurança
          </h2>
          <div className="grid grid-cols-2 gap-4">
            <Form.Group className="relative">
              <Form.Label>Senha atual</Form.Label>
              <InputV2
                id="password"
                type="password"
                hasError={!!errors?.senhaAtual}
                placeholder="Digite a senha"
                placeholderStyle="normal"
                {...register('senhaAtual')}
              />
              <Form.Message error={errors?.senhaAtual} />
            </Form.Group>
            <Form.Group>
              <Form.Label>&nbsp;</Form.Label>
              <Link
                className="ml-2 mt-3 text-left text-sm font-semibold text-red-500 hover:underline"
                to={PagePath.RecuperarSenha}
                relative="path"
              >
                Esqueceu a senha?
              </Link>
            </Form.Group>
            <Form.Group>
              <Form.Label>Nova senha</Form.Label>
              <InputV2
                type="password"
                placeholder="Digite sua nova senha"
                placeholderStyle="normal"
                {...register('novaSenha')}
                hasError={!!errors?.novaSenha}
              />
              <Form.Message error={errors?.novaSenha} />
              <div>
                <p className="mb-0 mt-4 text-left text-sm">
                  <strong>Requisitos para nova senha</strong>
                </p>
                <ul className="mt-1 list-disc pl-[1.3rem] text-left text-sm">
                  <li
                    className={
                      passwordValidation.length
                        ? 'text-green-500'
                        : 'text-gray-500'
                    }
                    style={{
                      '--marker-size': '1.5rem',
                    }}
                  >
                    Mínimo de 8 caracteres
                  </li>
                  <li
                    className={
                      passwordValidation.uppercase
                        ? 'text-green-500'
                        : 'text-gray-500'
                    }
                    style={{
                      '--marker-size': '1.5rem',
                    }}
                  >
                    Pelo menos uma letra maiúscula
                  </li>
                  <li
                    className={
                      passwordValidation.lowercase
                        ? 'text-green-500'
                        : 'text-gray-500'
                    }
                    style={{
                      '--marker-size': '1.5rem',
                    }}
                  >
                    Pelo menos uma letra minúscula
                  </li>
                  <li
                    className={
                      passwordValidation.numberOrSymbol
                        ? 'text-green-500'
                        : 'text-gray-500'
                    }
                    style={{
                      '--marker-size': '1.5rem',
                    }}
                  >
                    Pelo menos um número ou símbolo
                  </li>
                </ul>
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>Confirmar nova senha</Form.Label>
              <InputV2
                type="password"
                placeholder="Confirme sua nova senha"
                placeholderStyle="normal"
                {...register('confirmarNovaSenha')}
                hasError={!!errors?.confirmarNovaSenha}
              />
              <Form.Message error={errors?.confirmarNovaSenha} />
            </Form.Group>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              window.history.back();
            }}
          >
            <Button
              label="Voltar"
              variant="ghost"
              className="mt-[22px] h-[46px] w-[200px]"
            />
          </Link>
          <button type="submit" className="mt-[22px] h-[46px] w-[200px]">
            <span className={styles.button({ loading })}>Salvar</span>
            <span className={styles.loader({ loading })}></span>
          </button>
        </div>
        <style>
          {`
          li::marker {
            font-size: var(--marker-size);
            color: inherit;
          }
          `}
        </style>
      </Form>

      {isModalOpen && <ModalPerfilConfirm />}
    </>
  );
}

export default MeusDados;
