import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { BiEditAlt, BiPlus } from 'react-icons/bi';
import { GoAlertFill } from 'react-icons/go';
import { MdDeleteOutline } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button } from '@components/button';
import { Modal } from '@components/modal';
import { ButtonV2 } from '@components/new-components/button-v2';
import { Form } from '@components/new-components/form';
import { InputV2 } from '@components/new-components/input-v2';
import { SelectV2 } from '@components/new-components/select-v2';
import { Switch } from '@components/switch';
import { Tab } from '@components/tab';
import api from '@services/api';
import { useAirports } from '@stores/airports';
import { formatAirportTableInformations, formatTime } from '@system/utils';
import { toaster } from '@system/utils/toaster';
import LoadingAnimation from '../components/LoadingAnimation';
import { PagePath } from '../pages-config';
import PageHeader from '@components/page-header';

const validationSchema = yup.object().shape({
  id: yup.number().nullable(),
  origin: yup.string().required('Origem é obrigatório'),
  departureTime: yup.string().required('Hora de saída é obrigatório'),
  destination: yup.string().required('Destino é obrigatório'),
  arrivalTime: yup.string().required('Hora de chegada é obrigatório'),
  scales: yup.array().of(
    yup.object().shape({
      scaleOrigin: yup.string().required('Origem da escala é obrigatório'),
      scaleDepartureTime: yup
        .string()
        .required('Hora de saída da escala é obrigatório'),
      scaleDestination: yup
        .string()
        .required('Destino da escala é obrigatório'),
      scaleArrivalTime: yup
        .string()
        .required('Hora de chegada da escala é obrigatório'),
      isNew: yup.boolean(),
    }),
  ),
  connections: yup.array().of(
    yup.object().shape({
      connectionOrigin: yup
        .string()
        .required('Origem da conexão é obrigatório'),
      connectionFlightNumber: yup
        .number()
        .required('Número do voo da conexão é obrigatório'),
      isNew: yup.boolean(),
    }),
  ),
  flightNumber: yup.number().nullable().required('Número do voo é obrigatório'),
});

export default function AereoFrotaRotas() {
  const {
    control,
    handleSubmit,
    register,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: null,
      origin: '',
      departureTime: '',
      destination: '',
      arrivalTime: '',
      scales: [],
      connections: [],
      flightNumber: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'scales',
  });
  const {
    fields: connectionFields,
    append: connectionAppend,
    remove: connectionRemove,
  } = useFieldArray({
    control,
    name: 'connections',
  });

  const { id } = useParams();
  const { airports, methods } = useAirports();
  const [loading, setLoading] = useState(false);
  const [dataBase, setDataBase] = useState([]);
  const [dataRotas, setDataRotas] = useState([]);
  const [shouldReloadAircrafts, setShouldReloadAircrafts] = useState(true);
  const [rota, setRota] = useState('');
  const [aeroportoOrigem, setAeroportoOrigem] = useState('');
  const [aeroportoDestino, setAeroportoDestino] = useState('');
  const [companhiaData, seCompanhiaData] = useState([]);
  const [duracao, setDuracao] = useState('');
  const [outrodia, setOutrodia] = useState('');
  const [operadopor, setOperadopor] = useState('');
  const [toogleOperadopor, setToogleOperadopor] = useState(false);
  const [active, setActive] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentIndex, setIndex] = useState(0);

  const handleChange = (newIndex) => setIndex(newIndex);

  function loadCompanies() {
    api
      .get('/companies')
      .then((response) => {
        if (response.data.length > 0) {
          seCompanhiaData(response.data);
        } else {
          toaster('error', 'Registro não encontrado!');
        }
      })
      .catch((error) => alert(error));
  }

  async function getRoutesByAirplaneId() {
    try {
      const { data } = await api.get(`/fleet/${id}/routes`);
      setDataRotas(data);
    } catch (error) {
      console.log(error);
    }
  }

  function mapFleetData(fleet) {
    return {
      aeronave: fleet.manufacture?.aircraftName,
      id: fleet.id,
      fabricante: fleet.manufacture?.id,
      nome: fleet.airline?.name,
      logo: fleet.airline?.logo,
      fabricacao: fleet.manufacturing,
      ultimaRevisao: fleet.lastInspection,
      angarPrincipal: fleet.mainHangar,
      registro: fleet.registry,
      primeiraClasse: fleet.airline?.firstClass,
      classeExecutiva: fleet.airline?.executive,
      classeEconomica: fleet.airline?.economic,
    };
  }

  async function listAirplanes() {
    setLoading(true);
    cleanForm();
    try {
      const { data } = await api.get(`/fleet/${id}`);

      if (data) {
        const formatedFleet = mapFleetData(data);

        setDataBase(formatedFleet);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    return () => {
      loadCompanies();
      methods.getAirports();
    };
  }, []);

  useEffect(() => {
    getRoutesByAirplaneId();
    if (shouldReloadAircrafts) {
      listAirplanes();
      setShouldReloadAircrafts(false);
    }
  }, [shouldReloadAircrafts, rota]);

  function cleanForm() {
    setAeroportoOrigem('');
    setAeroportoDestino('');
    setDuracao('');
    setRota('');
    setOutrodia('');
    setOperadopor('');
    setToogleOperadopor(false);
    reset({
      id: null,
      origin: '',
      departureTime: '',
      destination: '',
      arrivalTime: '',
      scales: [],
      connections: [],
      flightNumber: null,
    });
  }

  async function listScales(routeId) {
    setLoading(true);
    try {
      const { data } = await api.get(`/route/${routeId}/scales`);

      setLoading(false);

      const scaleList = data.map((scale) => {
        return {
          scaleId: scale.id,
          scaleDestination: scale.destination,
          scaleOrigin: scale.origin,
          scaleDepartureTime: scale.departure,
          scaleArrivalTime: scale.arrival,
        };
      });

      return scaleList;
    } catch (error) {
      console.log(error);
    }
  }

  async function listConnections(routeId) {
    try {
      const { data } = await api.get(`/route/${routeId}/connections`);
      const connectionList = data.map((connection) => {
        return {
          connectionId: connection.id,
          connectionOrigin: connection.aeroporto,
          connectionFlightNumber: connection.voo,
        };
      });

      return connectionList;
    } catch (error) {
      console.log(error);
    }
  }

  async function editRoute(data) {
    window.scrollTo(0, 0);
    setLoading(true);
    cleanForm();
    setRota(data.id);
    setAeroportoOrigem(data.origem);
    setAeroportoDestino(data.destino);
    setDuracao(data.duracao);
    setOutrodia(data.outrodia);
    setActive(data.ativo);
    setOperadopor(data.operadopor);

    const scales = await listScales(data.id);
    const connections = await listConnections(data.id);

    reset({
      id: data?.id,
      origin: data?.origem,
      departureTime: data?.saida,
      destination: data?.destino,
      arrivalTime: data?.chegada,
      flightNumber: data?.voo,
      scales: scales?.length ? scales : [],
      connections: connections?.length ? connections : [],
    });

    if (data.operadopor) {
      setToogleOperadopor(true);
    }
    setTimeout(() => setLoading(false), 1000);
  }

  function resetForm() {
    window.scrollTo(0, 0);
    setLoading(true);
    cleanForm();
    setTimeout(() => setLoading(false), 1000);
  }

  function toggleOutrodia() {
    if (outrodia == '1') {
      setOutrodia('');
    } else {
      setOutrodia('1');
    }
  }

  function toggleEscala() {
    if (fields.length === 0) {
      if (connectionFields.length > 0) {
        connectionRemove();
      }
      addScale();
    } else {
      remove();
    }
  }

  function toggleConexao() {
    if (connectionFields.length === 0) {
      if (fields.length > 0) {
        remove();
      }
      addConnection();
    } else {
      connectionRemove();
    }
  }

  function toggleoperadopor() {
    if (toogleOperadopor) {
      setToogleOperadopor(false);
      setOperadopor('');
    } else {
      setToogleOperadopor(true);
    }
  }

  async function handleDeleteScaleById(scaleId, route) {
    try {
      await api.delete(`/layover/${scaleId}`);

      toaster('success', 'Deletado com sucesso!');
      setLoading(false);
      listScales(route);
    } catch (error) {
      toaster('error', 'Falha ao tentar deletar escala');
    }
  }

  async function handleDisableScale(route) {
    try {
      await api.patch(`/route/disable/${route}/scale`);
      setLoading(false);
      listScales(rota);
    } catch (error) {
      toaster('error', 'Falha ao tentar deletar escala');
    }
  }

  async function deleteScale(idEscala, rota) {
    setLoading(true);
    if (!rota) {
      return setLoading(false);
    }
    if (getValues('scales').length > 0) {
      Promise.resolve([
        handleDisableScale(rota),
        handleDeleteScaleById(idEscala, rota),
      ]);
    } else {
      await handleDeleteScaleById(idEscala, rota);
    }
  }

  async function handleDeleteConnectionById(connectionId) {
    try {
      await api.delete(`/connection/${connectionId}`);
      toaster('success', 'Deletado com sucesso!');
      setLoading(false);
    } catch (error) {
      toaster('error', 'Falha ao tentar deletar escala');
    }
  }

  async function handleDisableConnection(route) {
    try {
      await api.patch(`/route/disable/${route}/connection`);
      setLoading(false);
      listScales(rota);
    } catch (error) {
      toaster('error', 'Falha ao tentar desabilitar conexão');
    }
  }

  async function deleteConexao(idConexao) {
    setLoading(true);

    if (!rota) {
      return setLoading(false);
    }
    if (getValues('connections').length > 0) {
      Promise.resolve([
        handleDisableConnection(rota),
        handleDeleteConnectionById(idConexao),
      ]);
    } else {
      await handleDeleteScaleById(idConexao);
    }
  }

  async function handleAddNewConnections(connection) {
    try {
      const payload = {
        rota,
        aeroporto: connection.connectionOrigin,
        voo: connection.connectionFlightNumber,
      };

      await api.post(`/connection`, { ...payload });
    } catch (error) {
      console.log(error);
    }
  }

  async function handleUpdateExistantConnections(connection) {
    try {
      const payload = {
        rota,
        aeroporto: connection.connectionOrigin,
        voo: connection.connectionFlightNumber,
      };
      await api.patch(`/connection/${connection.connectionId}`, { ...payload });
    } catch (error) {
      console.log(error);
    }
  }

  async function handleAddNewScales(scale) {
    try {
      const payload = {
        rota,
        origem: scale.scaleOrigin,
        destino: scale.scaleDestination,
        saida: scale.scaleDepartureTime,
        chegada: scale.scaleArrivalTime,
      };
      await api.post(`/layover/`, { ...payload });
    } catch (error) {
      console.log(error, 'Falha ao tentar criar uma nova escala');
    }
  }

  async function handleUpdateExistantScales(scale) {
    try {
      const payload = {
        rota,
        origem: scale.scaleOrigin,
        destino: scale.scaleDestination,
        saida: scale.scaleDepartureTime,
        chegada: scale.scaleArrivalTime,
      };
      await api.patch(`/layover/${scale.scaleId}`, { ...payload });
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDeleteConnectionByRoute() {
    try {
      await api.delete(`/connection/route/${rota}`);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDeleteScaleByRoute() {
    try {
      await api.delete(`/layover/route/${rota}`);
    } catch (error) {
      console.log(error);
    }
  }

  const onSubmit = async (data) => {
    setLoading(true);

    const connections = getValues('connections');
    const scales = getValues('scales');

    const { origin, destination, departureTime, arrivalTime, flightNumber } =
      data;

    const checkEscala = scales.length > 0;
    const checkConexao = connections.length > 0;

    const timediff = `timediff('2024-01-0${outrodia == 1 ? '2' : '1'} ${arrivalTime}', '2024-01-01 ${departureTime}')`;

    const routeData = {
      fleetId: id,
      origin,
      destination,
      departure: departureTime || null,
      arrival: arrivalTime || null,
      flight: flightNumber || null,
      otherday: outrodia || 0,
      connection: checkConexao ? connections.length : 0,
      layover: checkEscala ? scales.length : 0,
      duration: timediff,
      operatedBy: operadopor || null,
      active,
    };

    try {
      // SE TIVER ROTA, ATUALIZA
      if (rota) {
        // Atualiza rota existente
        await api.patch(`/route/${rota}`, routeData);

        // Atualiza conexões e escalas relacionadas
        if (connections.length > 0) {
          for (const connection of connections) {
            if (connection.isNew) {
              await handleAddNewConnections(connection);
            } else {
              await handleUpdateExistantConnections(connection);
            }
          }
        } else {
          await handleDeleteConnectionByRoute(rota);
        }

        if (scales.length > 0) {
          for (const scale of scales) {
            if (scale.isNew) {
              await handleAddNewScales(scale, rota);
            } else {
              await handleUpdateExistantScales(scale);
            }
          }
        } else {
          await handleDeleteScaleByRoute(rota);
        }

        toaster('success', 'Rota atualizada com sucesso!');
      } else {
        // Cria nova rota
        const response = await api.post('/route', routeData);
        const newRoute = response.data;

        // Adiciona conexões e escalas para a nova rota
        if (connections.length > 0) {
          for (const connection of connections) {
            await handleAddNewConnections(connection, newRoute.id);
          }
        }

        if (scales.length > 0) {
          for (const scale of scales) {
            await handleAddNewScales(scale, newRoute.id);
          }
        }

        toaster('success', 'Rota criada com sucesso!');
      }

      setShouldReloadAircrafts(true);
    } catch (error) {
      toaster('error', 'Erro ao salvar rota!');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const addScale = () => {
    append({
      scaleOrigin: '',
      scaleDepartureTime: '',
      scaleDestination: '',
      scaleArrivalTime: '',
      isNew: true,
    });
  };
  const addConnection = () => {
    connectionAppend({
      connectionOrigin: '',
      connectionFlightNumber: '',
      isNew: true,
    });
  };

  const airportOptions = airports.map((data) => {
    return {
      label: `${data.iata} - ${data.nome} - ${data.cidade} / ${data.uf}`,
      value: data.iata,
    };
  });
  const companyOptionsMap = companhiaData.map((data) => {
    return {
      label: data.apelido,
      value: data.apelido,
    };
  });

  function confirmInactivate() {
    setActive((prev) => !prev);
    setModalOpen((prev) => !prev);
  }

  function handleChangeStatus() {
    if (active && rota) {
      return setModalOpen((prev) => !prev);
    }
    return setActive((prev) => !prev);
  }

  if (loading) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Configurações', link: PagePath.Admin },
          {
            label: 'Aéreo - Frotas',
            link: PagePath.AereoFrota,
          },
          {
            label: 'Aeronave',
            link: PagePath.AereoFrotaEditar.replace(':id', id),
          },
          {
            label: 'Mapa de assentos',
            link: PagePath.AereoFrotaAssentos.replace(':id', id),
          },
          {
            label: 'Rotas',
            link: PagePath.AereoFrotaRotas,
          },
        ]}
        title="Rotas"
      />
      <div className="flex flex-col gap-4">
        <div className="flex max-h-[68px] w-full items-center gap-[19px] rounded-md border border-solid border-neutral-300 bg-white px-5 py-[20px]">
          <img className="m-0 w-[39px] p-0" src={dataBase.logo} />
          <span className="m-0 p-0 text-heading-2 text-primary">
            {dataBase.aeronave}
          </span>
        </div>

        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="flex w-full flex-col gap-4 rounded-md bg-white p-5"
        >
          <div className="flex items-center gap-[10px]">
            <Form.Group className="w-full">
              <Form.Label>Origem</Form.Label>
              <SelectV2
                options={airportOptions}
                value={getValues('origin')}
                {...register('origin')}
              />
              <Form.Message error={errors?.origin} />
            </Form.Group>
            <Form.Group className="w-full">
              <Form.Label>Destino</Form.Label>
              <SelectV2
                options={airportOptions}
                value={getValues('destination')}
                {...register('destination')}
              />
              <Form.Message error={errors?.destination} />
            </Form.Group>
          </div>

          <div className="flex w-full gap-[10px]">
            <Form.Group className="w-full">
              <Form.Label>Horário estimado de saída</Form.Label>
              <InputV2 {...register('departureTime')} type="time" required />
              <InputV2.Icon icon={<AiOutlineClockCircle />} />
              <Form.Message error={errors?.departureTime} />
            </Form.Group>

            <Form.Group className="w-full">
              <Form.Label>Horário estimado de chegada</Form.Label>
              <InputV2 {...register('arrivalTime')} type="time" required />
              <InputV2.Icon icon={<AiOutlineClockCircle />} />
              <Form.Message error={errors?.arrivalTime} />
            </Form.Group>

            <Form.Group className="w-full">
              <Form.Label>Número do voo</Form.Label>
              <InputV2 type="number" {...register('flightNumber')} required />
              <Form.Message error={errors?.flightNumber} />
            </Form.Group>
          </div>
          <div className="flex h-[50px] items-center gap-6">
            <Switch
              label="Chega no outro dia"
              checked={outrodia == '1'}
              onChange={toggleOutrodia}
            />
            <Switch
              label="Com escala"
              checked={fields.length > 0}
              onChange={toggleEscala}
            />

            <Switch
              label="Com conexão (troca de aeronave)"
              checked={connectionFields.length > 0}
              onChange={toggleConexao}
            />
            <Switch
              label="Operado por"
              checked={toogleOperadopor}
              onChange={toggleoperadopor}
            />
            <Switch
              label="Ativa"
              checked={active}
              onChange={handleChangeStatus}
            />
          </div>

          {/* --------------------- Escalas ---------------------- */}
          <div
            className={`flex flex-col !gap-4 rounded-md !p-4 transition-all ${fields.length > 0 ? 'pontilhado w-full' : 'hidden'}`}
          >
            {fields.map((field, index) => (
              <div className="flex w-full flex-col !gap-4" key={field.id}>
                <div className="flex w-full justify-between">
                  <span className="text-heading-3 !text-primary">
                    Escala {index + 1}
                  </span>
                  <MdDeleteOutline
                    className="cursor-pointer text-secondary"
                    title="Fechar"
                    size={24}
                    onClick={() => {
                      deleteScale(getValues('scales')[index].scaleId, rota);
                      remove(index);
                    }}
                  />
                </div>
                <div className="flex gap-2.5 border-x-0 border-b border-t-0 border-solid border-neutral-300 pb-4">
                  <div className="flex w-full flex-col items-center gap-4">
                    <Form.Group className="w-full">
                      <Form.Label>Origem</Form.Label>
                      <SelectV2
                        options={airportOptions}
                        {...register(`scales.${index}.scaleOrigin`)}
                        value={getValues(`scales.${index}.scaleOrigin`)}
                      />
                    </Form.Group>
                    <Form.Group className="w-full">
                      <Form.Label>Destino</Form.Label>
                      <SelectV2
                        options={airportOptions}
                        {...register(`scales.${index}.scaleDestination`)}
                        value={getValues(`scales.${index}.scaleDestination`)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-span-2 col-start-4 flex flex-col items-center gap-4">
                    <Form.Group className="w-80">
                      <Form.Label>Horário estimado de saída</Form.Label>
                      <InputV2
                        type="time"
                        {...register(`scales.${index}.scaleDepartureTime`)}
                        required
                      />
                      <InputV2.Icon icon={AiOutlineClockCircle} />
                    </Form.Group>
                    <Form.Group className="w-80">
                      <Form.Label>Horário estimado de chegada</Form.Label>
                      <InputV2
                        type="time"
                        {...register(`scales.${index}.scaleArrivalTime`)}
                        required
                      />
                      <InputV2.Icon icon={AiOutlineClockCircle} />
                    </Form.Group>
                  </div>
                </div>
              </div>
            ))}
            <span
              className="flex cursor-pointer items-center gap-2 text-body font-semibold text-secondary"
              onClick={addScale}
            >
              <BiPlus /> Adicionar nova escala
            </span>
          </div>

          {/* --------------------- Conexões ---------------------- */}
          <div
            className={`flex flex-col !gap-4 rounded-md !p-4 ${connectionFields.length > 0 ? 'pontilhado w-full' : 'hidden'}`}
          >
            {connectionFields.map((field, index) => (
              <div className="flex w-full flex-col !gap-4" key={field.id}>
                <div className="flex w-full justify-between">
                  <span className="text-heading-3 !text-primary">
                    Conexão {index + 1}
                  </span>
                  <MdDeleteOutline
                    className="cursor-pointer text-secondary"
                    title="Fechar"
                    size={24}
                    onClick={() => {
                      deleteConexao(
                        getValues('connections')[index].connectionId,
                      );
                      connectionRemove(index);
                    }}
                  />
                </div>
                <div className="grid grid-cols-1 grid-rows-1 gap-[10px] border-x-0 border-b border-t-0 border-solid border-neutral-300 pb-4">
                  <div className="flex w-full gap-2.5">
                    <Form.Group className="w-full">
                      <Form.Label>Origem</Form.Label>
                      <SelectV2
                        options={airportOptions}
                        {...register(`connections.${index}.connectionOrigin`)}
                        value={getValues(
                          `connections.${index}.connectionOrigin`,
                        )}
                      />
                    </Form.Group>
                    <Form.Group className="w-80">
                      <Form.Label>Voo</Form.Label>
                      <InputV2
                        {...register(
                          `connections.${index}.connectionFlightNumber`,
                        )}
                        required
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            ))}
            <span
              className="flex cursor-pointer items-center gap-2 text-body font-semibold text-secondary"
              onClick={addConnection}
            >
              <BiPlus /> Adicionar nova conexão
            </span>
          </div>

          <div
            className={`flex flex-col !gap-4 !p-4 ${toogleOperadopor ? 'pontilhado w-full' : 'hidden'}`}
          >
            <div className="flex w-full justify-between">
              <span className="text-heading-3 !text-primary">Operado por</span>
              <MdDeleteOutline
                className="cursor-pointer text-secondary"
                title="Fechar"
                size={24}
                onClick={toggleoperadopor}
              />
            </div>
            <SelectV2
              label="Cia aérea"
              value={operadopor}
              onChange={(e) => setOperadopor(e.target.value)}
              options={companyOptionsMap}
              required={toogleOperadopor}
            />
          </div>
          <div className="flex justify-end gap-6 pt-[10px]">
            <ButtonV2 size="large" className="!w-[200px]" type="submit">
              <ButtonV2.Text>Salvar</ButtonV2.Text>
            </ButtonV2>
          </div>
        </Form>

        <div className="mt-3 w-full rounded-md bg-white p-5">
          <div className="mb-5 flex w-full items-center justify-between">
            <span className="m-0 text-heading-2 text-primary">
              Listagem de rotas
            </span>
            <ButtonV2 size="large" className="!w-[200px]" onClick={resetForm}>
              <ButtonV2.Text>Criar nova rota</ButtonV2.Text>
            </ButtonV2>
          </div>
          <Tab value={currentIndex} onChange={handleChange}>
            <div className="mt-2 flex w-full flex-col rounded-md border border-solid !border-neutral-300">
              <Tab.Heads>
                <Tab.Item label="Ativas" index={0} />
                <Tab.Item label="Inativas" index={1} />
              </Tab.Heads>
              <Tab.ContentWrapper>
                <Tab.Content index={0}>
                  <div className="flex w-full border-x-0 border-b border-t-0 border-solid border-primary">
                    <div className="flex w-full items-center justify-start pb-5 pl-[9px] pt-[10px]">
                      <span className="text-[14px] text-sm font-semibold text-primary">
                        Origem
                      </span>
                    </div>
                    <div className="-ml-4 flex w-full items-center justify-start pb-5 pt-[10px]">
                      <span className="text-[14px] text-sm font-semibold text-primary">
                        Destino
                      </span>
                    </div>
                    <div className="!mr-2 !min-h-6 min-w-[32px] !px-1 !py-[2px]" />
                  </div>
                  <div className="flex w-full flex-col !divide-x-0 !divide-y divide-solid divide-neutral-300">
                    {dataRotas
                      .filter((route) => route.ativo === 1)
                      .map((data, index) => (
                        <div
                          key={index}
                          className="flex min-h-20 w-full cursor-pointer items-center gap-10 px-3 py-4 last:-mb-4 hover:bg-secondary-100"
                          onClick={() => editRoute(data)}
                        >
                          <span className="m-0 w-full text-[14px] font-normal text-neutral-800">
                            <b>{formatTime(data.saida)}</b> -{' '}
                            {formatAirportTableInformations(
                              airports,
                              data.origem,
                            )}
                          </span>
                          <span className="m-0 w-full text-[14px] font-normal text-neutral-800">
                            <b>{formatTime(data.chegada)}</b> -{' '}
                            {formatAirportTableInformations(
                              airports,
                              data.destino,
                            )}
                          </span>
                          <div className="mr-2 h-max px-1 py-[2px]">
                            <BiEditAlt
                              className="cursor-pointer text-secondary"
                              size={24}
                              onClick={() => editRoute(data)}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </Tab.Content>
                <Tab.Content index={1}>
                  <div className="flex w-full gap-10 border-x-0 border-b border-t-0 border-solid border-primary">
                    <div className="flex w-full items-center justify-start pb-5 pl-[9px] pt-[10px]">
                      <span className="text-[14px] text-sm font-semibold text-primary">
                        Origem
                      </span>
                    </div>
                    <div className="m-0 flex w-full items-center justify-start pb-5 pt-[10px]">
                      <span className="text-[14px] text-sm font-semibold text-primary">
                        Destino
                      </span>
                    </div>
                    <div className="!mr-2 !min-h-6 min-w-[32px] !px-1 !py-[2px]" />
                  </div>
                  <div className="flex w-full flex-col !divide-x-0 !divide-y divide-solid divide-neutral-300">
                    {dataRotas
                      .filter((route) => route.ativo === 0)
                      .map((data, index) => (
                        <div
                          key={index}
                          className="flex min-h-20 w-full cursor-pointer items-center gap-10 border-x-0 border-b border-t-0 border-neutral-300 px-3 py-4 last:-mb-4 hover:bg-secondary-100"
                          onClick={() => editRoute(data)}
                        >
                          <span className="m-0 w-full text-[14px] font-normal text-neutral-800">
                            <b>{formatTime(data.saida)}</b> -{' '}
                            {`${formatAirportTableInformations(airports, data.origem)}`}
                          </span>
                          <span className="m-0 w-full text-[14px] font-normal text-neutral-800">
                            <b>{formatTime(data.chegada)}</b> -{' '}
                            {formatAirportTableInformations(
                              airports,
                              data.destino,
                            )}
                          </span>
                          <div className="mr-2 h-max px-1 py-[2px] ">
                            <BiEditAlt
                              className="cursor-pointer text-secondary"
                              size={24}
                              onClick={() => editRoute(data)}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </Tab.Content>
              </Tab.ContentWrapper>
            </div>
          </Tab>
        </div>
      </div>

      <div className="mt-[25px] flex w-full justify-end">
        <Link to={PagePath.AereoSaidas} relative="path">
          <ButtonV2 size="large" ghost className="!w-[200px]" type="submit">
            <ButtonV2.Text>Criar saídas</ButtonV2.Text>
          </ButtonV2>
        </Link>
      </div>

      <Modal open={modalOpen}>
        <div className="flex flex-col items-center justify-center px-[190px] py-[60px]">
          <GoAlertFill className="text-warning-500" size={38} />
          <span className="mt-3 whitespace-nowrap text-center text-heading-1 text-warning-500">
            Tem certeza que deseja inativar a rota {aeroportoOrigem} -{' '}
            {aeroportoDestino}?
          </span>
          <p className="mb-[30px] mt-[15px] text-center text-heading-4 text-neutral-800">
            Ao inativar a rota, todas as saídas que a <br /> incluírem serão
            desabilitadas.
          </p>
          <div className="mt-3 flex items-center justify-center gap-[30px]">
            <Button
              variant="ghost"
              label="Cancelar"
              onClick={() => setModalOpen(false)}
            />
            <Button label="Inativar rota" onClick={confirmInactivate} />
          </div>
        </div>
      </Modal>
    </>
  );
}
