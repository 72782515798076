import { useState, useEffect } from 'react';
import { PiPlusCircleFill, PiCaretRightBold } from 'react-icons/pi';
import { FiEdit } from 'react-icons/fi';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { BiSolidEdit } from 'react-icons/bi';
import LoadingAnimation from '../components/LoadingAnimation';
import PageHeader from '@components/page-header';
import { PagePath } from '../pages-config';
import api from '@services/api';

export default function AdminAirports() {
  const [alertType, setAlertType] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertText, setAlertText] = useState('');
  const [loading, setLoading] = useState(false);
  const [airportList, setAirportList] = useState([]);
  const [totalAirports, setTotalAirports] = useState(0);
  const [iata, setIata] = useState('');
  const [icao, setIcao] = useState('');
  const [name, setName] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');

  const [searchTerm, setSearchTerm] = useState('');
  const [isNewData, setIsNewData] = useState(true);
  const [masterTitle] = useState('Aeroportos');

  useEffect(() => {
    fetchAirports();
  }, []);

  async function fetchAirports() {
    setLoading(true);
    try {
      const response = await api.get('/airports');
      setAirportList(response.data);
      setTotalAirports(response.data.length);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmitForm(e) {
    e.preventDefault();
    setLoading(true);

    const airportData = {
      iata: iata.toUpperCase().trim(),
      icao: icao.toUpperCase().trim(),
      name: name.trim(),
      city: city.trim(),
      state,
    };

    try {
      if (isNewData) {
        const createResponse = await api.post('/airports', airportData);
        if (createResponse.status === 201 || createResponse.status === 200) {
          setAlertType('alert-success');
          setAlertTitle('Sucesso!');
          setAlertText('Cadastrado com sucesso.');
          setTimeout(() => setAlertType(''), 3000);
          clearForm();
          await fetchAirports();
        }
      } else {
        const updateResponse = await api.put(
          `/airports/${airportData.iata}`,
          airportData,
        );
        if (updateResponse.status === 200) {
          setAlertType('alert-success');
          setAlertTitle('Sucesso!');
          setAlertText('Alterado com sucesso.');
          setTimeout(() => setAlertType(''), 3000);
          clearForm();
          await fetchAirports();
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setAlertType('alert-danger');
        setAlertTitle('Aeroporto já cadastrado!');
        setAlertText('Por favor, verifique os dados.');
      } else {
        setAlertType('alert-danger');
        setAlertTitle('Erro na Gravação!');
        setAlertText('Não foi possível gravar os dados.');
      }
      setTimeout(() => setAlertType(''), 3000);
    } finally {
      setLoading(false);
    }
  }

  function handleEditAirport(iata, icao, name, city, state) {
    setLoading(true);
    clearForm(false);
    setIata(iata);
    setIcao(icao);
    setName(name);
    setCity(city);
    setState(state);
    setTimeout(() => setLoading(false), 500);
  }

  function clearForm(resetNewData = true) {
    setIsNewData(resetNewData);
    setIata('');
    setIcao('');
    setName('');
    setCity('');
    setState('');
  }

  async function handleSearch() {
    if (!searchTerm.trim()) {
      await fetchAirports();
      return;
    }

    setLoading(true);
    setAlertType('');

    try {
      const search = searchTerm.trim().toUpperCase();
      const response = await api.get(`/airports/${search}`);

      if (response.data) {
        setAirportList([response.data]);
      } else {
        setAlertType('alert-danger');
        setAlertTitle('Não encontrado!');
        setAlertText(`Nenhum aeroporto encontrado com IATA "${search}".`);
        setAirportList([]);
        setTimeout(() => setAlertType(''), 3000);
      }
    } catch (error) {
      setAlertType('alert-danger');
      setAlertTitle('Não encontrado!');
      setAlertText(`Nenhum aeroporto encontrado com IATA "${searchTerm}".`);
      setAirportList([]);
      setTimeout(() => setAlertType(''), 3000);
    } finally {
      setLoading(false);
    }
  }

  function handleSearchChange(value) {
    setSearchTerm(value);
    setAlertType('');
    if (value.trim().length === 0) {
      fetchAirports();
    }
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      handleSearch();
    }
  }

  return (
    <>
      {loading && <LoadingAnimation />}

      <div className={alertType ? 'alert ' + alertType : 'hide'} role="alert">
        <h4 className={alertTitle ? '' : 'hide'}>{alertTitle}</h4>
        {alertText}
      </div>

      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Configurações', link: PagePath.Admin },
          { label: 'Aéreo - Aeroportos', link: PagePath.AdminAeroportos },
        ]}
        title={masterTitle}
      />

      <div className="contentAll">
        <div className="row">
          <div className="col">
            <form onSubmit={handleSubmitForm}>
              <div className="row">
                <div className="col-2">
                  <label>IATA</label>
                  <input
                    type="text"
                    value={iata}
                    onChange={(e) => setIata(e.target.value)}
                    required
                  />
                </div>
                <div className="col-2">
                  <label>ICAO</label>
                  <input
                    type="text"
                    value={icao}
                    onChange={(e) => setIcao(e.target.value)}
                    required
                  />
                </div>
                <div className="col-8">
                  <label>Nome</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-4">
                  <label>Estado</label>
                  <select
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    required
                  >
                    <option value="">Selecione</option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espirito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                  </select>
                </div>
                <div className="col-8">
                  <label>Cidade</label>
                  <input
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col"></div>
                <div className="col-3">
                  <button type="submit">
                    <span className={loading ? 'hide' : ''}>
                      <span className={isNewData ? '' : 'hide'}>
                        <PiCaretRightBold /> Salvar
                      </span>
                      <span className={!isNewData ? '' : 'hide'}>
                        <FiEdit /> Alterar
                      </span>
                    </span>
                    <span className={loading ? 'loader' : 'hide'}></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="contentAll mt-3">
        <div className="row lineButtons">
          <div className="col pl-0">
            <h4>{masterTitle}</h4>
          </div>

          <div className="col btnTable">
            <div className="buscaFiltro">
              <input
                type="text"
                className="inputSearch"
                placeholder="Procurar por IATA"
                value={searchTerm}
                onChange={(e) => handleSearchChange(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <FaMagnifyingGlass onClick={handleSearch} />
            </div>
            <div className="rightBtn">
              <div>{airportList.length}</div>
              <div>de</div>
              <div className="listTotal">{totalAirports}</div>
              <button
                type="button"
                className="btn btnNew"
                onClick={() => clearForm()}
              >
                Novo <PiPlusCircleFill />
              </button>
            </div>
          </div>
        </div>

        <div className="text-sm">
          <div className="table_list">
            <table className="tblDefault">
              <thead>
                <tr>
                  <th scope="col">IATA</th>
                  <th scope="col">ICAO</th>
                  <th scope="col">Nome</th>
                  <th scope="col">Cidade</th>
                  <th scope="col">UF</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {airportList.map((airport, index) => (
                  <tr
                    key={index}
                    onClick={() =>
                      handleEditAirport(
                        airport.iata,
                        airport.icao,
                        airport.name,
                        airport.city,
                        airport.state,
                      )
                    }
                    className={iata === airport.iata ? 'trSelect' : ''}
                  >
                    <td>{airport.iata}</td>
                    <td>{airport.icao}</td>
                    <td>{airport.name}</td>
                    <td>{airport.city}</td>
                    <td>{airport.state}</td>
                    <td>
                      <BiSolidEdit />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
