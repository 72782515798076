import { useEffect, useRef, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';

export function DropdownThreeDots({
  options = [],
  data = null,
  isLoading = false,
  onClicked = () => {},
  onClickOption = (option) => {},
}) {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  function handleDropdownClick() {
    setIsOpen((state) => !state);

    if (onClicked) onClicked();
  }

  function handleOptionClick(option) {
    if (isLoading) {
      return;
    }
    option.onClick && option.onClick(data);

    if (onClickOption) onClickOption(option);
    setIsOpen(false);
  }

  return (
    <div className="dropdown relative">
      <div
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        role="button"
        onClick={() => handleDropdownClick()}
      >
        <BsThreeDotsVertical color="#E41047" size={20} />
      </div>
      <ul
        className={`dropdown-menu shadow-md absolute right-[42px] top-[28px] z-30 m-0 w-[100vw] max-w-[226px] rounded-md bg-white p-2 shadow-header [list-style:none] ${isOpen ? 'block' : 'hidden'}`}
        aria-labelledby="dropdownMenuButton"
        ref={dropdownRef}
      >
        {options.map((option, index) => (
          <li
            key={index}
            role="button"
            onClick={() => handleOptionClick(option)}
            className="dropdown-item rounded-md px-2 py-[6px] text-sm font-normal text-neutral-800 hover:bg-secondary-100 hover:text-secondary"
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
}
