import { GoCheckCircle } from 'react-icons/go';
import { Button } from '@components/button';
import { Modal } from '@components/modal';

export function ModalActiveAccountSuccess({ open, handleClose }) {
  return (
    <Modal size="medium" open={open} handleClose={handleClose}>
      <div className="py-7">
        <div className="flex flex-col items-center justify-center gap-10">
          <div className="flex flex-col gap-4 ">
            <div className="text-center">
              <GoCheckCircle size={40} className="text-price" />
            </div>
            <p className="m-0 text-center text-2xl font-semibold text-price">
              Conta ativada com sucesso!
            </p>
            <p className="m-0 max-w-[440px] text-center leading-6 text-neutral-800">
              Você será <b>redirecionado automaticamente</b> para a próxima
              etapa em instantes.
            </p>
            <p className="mb-0 mt-4 text-sm font-normal text-neutral-800">
              Se o redirecionamento não ocorrer, clique no botão abaixo.
            </p>
          </div>
          <div className="flex h-[48px] justify-center gap-4">
            <Button
              className="px-4"
              onClick={handleClose}
              variant="ghost"
              label="Ir agora"
              type="button"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
