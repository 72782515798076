export default {
  USER_ACTIVATION_PAGE_VIEWED: 'UserActivationPageViewed',
  USER_ACTIVATION_GOOGLE_BUTTON_CLICKED: 'UserActivationGoogleButtonClicked',
  USER_ACTIVATION_REGULAR_BUTTON_CLICKED: 'UserActivationRegularButtonClicked',
  USER_ACTIVATION_SUCESS_MODAL_PAGE_VIEWED:
    'UserActivationSucessModalPageViewed',
  USER_ACTIVATION_SUCESS_MODAL_BUTTON_CLICKED:
    'UserActivationSucessModalButtonClicked',
  USER_ACTIVATION_EXPIRED_MODAL_PAGE_VIEWED:
    'UserActivationExpiredModalPageViewed',
  USER_LIST_PAGE_VIEWED: 'UserListPageViewed',
  USER_LIST_CHANGE_PROFILE_INPUT_CLICKED: 'UserListChangeProfileInputClicked',
  USER_LIST_CHANGE_COMPANY_INPUT_CLICKED: 'UserListChangeCompanyInputClicked',
  USER_LIST_ACTIONS_BUTTON_CLICKED: 'UserListActionsButtonClicked',
  USER_LIST_ACTIONS_EXCLUSION_BUTTON_CLICKED:
    'UserListActionsExclusionButtonClicked',
  USER_LIST_ACTIONS_RESEND_BUTTON_CLICKED: 'UserListActionsResendButtonClicked',
  USER_LIST_EXPAND_RESULTS_BUTTON_CLICKED: 'UserListExpandResultsButtonClicked',
  USER_LIST_EXCLUSION_CONFIRMATION_MODAL_PAGE_VIEWED:
    'UserListExclusionConfirmationModalPageViewed',
  USER_LIST_EXCLUSION_CONFIRMATION_MODAL_BUTTON_CLICKED:
    'UserListExclusionConfirmationModalButtonClicked',
  USER_LIST_RESEND_CONFIRMATION_MODAL_BUTTON_CLICKED:
    'UserListResendConfirmationModalButtonClicked',
  USER_LIST_INVITE_BUTTON_CLICKED: 'UserListInviteButtonClicked',
  USER_LIST_INVITE_MODAL_PAGE_VIEWED: 'UserListInviteModalPageViewed',
  USER_LIST_INVITE_MODAL_ADD_USER_BUTTON_CLICKED:
    'UserListInviteModalAddUserButtonClicked',
  USER_LIST_INVITE_MODAL_SEND_BUTTON_CLICKED:
    'UserListInviteModalSendButtonClicked',
  USER_LIST_INVITE_MODAL_REMOVE_USER_BUTTON_CLICKED:
    'UserListInviteModalRemoveUserButtonClicked',
  USER_LIST_INVITE_MODAL_SUCCESS_PAGE_VIEWED:
    'UserListInviteModalSuccessPageViewed',
  USER_LIST_INVITE_MODAL_PARTIAL_PAGE_VIEWED:
    'UserListInviteModalPartialPageViewed',
  USER_LIST_INVITE_MODAL_FAILED_PAGE_VIEWED:
    'UserListInviteModalFailedPageViewed',
  MY_PROFILE_SAVE_BUTTON_CLICKED: 'MyProfileSaveButtonClicked',
  MY_PROFILE_PAGE_VIEWED: 'MyProfilePageViewed',
  MY_PROFILE_SUCCESS_MODAL_PAGE_VIEWED: 'MyProfileSuccessModalPageViewed',
  MY_PROFILE_SUCCESS_MODAL_BUTTON_CLICKED: 'MyProfileSuccessModalButtonClicked',
  USER_LIST_ACTIONS_EDIT_BUTTON_CLICKED: 'UserListActionsEditButtonClicked',
  USER_LIST_ACTIONS_INACTIVE_BUTTON_CLICKED:
    'UserListActionsInactiveButtonClicked',
  USER_LIST_EDIT_CONFIRMATION_MODAL_PAGE_VIEWED:
    'UserListEditConfirmationModalPageViewed',
  USER_LIST_EDIT_CONFIRMATION_MODAL_BUTTON_CLICKED:
    'UserListEditConfirmationModalButtonClicked',
  USER_LIST_EDIT_CONFIRMATION_SUCCESS_MODAL_PAGE_VIEWED:
    'UserListEditConfirmationSuccessModalPageViewed',
  REPORT_ROOMING_LIST_PAGE_VIEWED: 'ReportRoomingListPageViewed',
  REPORT_ROOMING_LIST_BUTTON_CLICKED: 'ReportRoomingListButtonClicked',
};
