import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RiInformationLine } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';
import { tv } from 'tailwind-variants';
import GoogleLogo from '@assets/svg/GoogleLogo';
import { Button } from '@components/button';
import { InputText } from '@components/input';
import { Form } from '@components/new-components/form';
import api from '@services/api';
import { useActivateAccount } from '@stores/authentication';
import { validatePassword } from '@system/utils/validatePassword';
import { ActivateAccountHeader } from './ActivateAccountHeader';
import { ModalActiveAccountSuccess } from './ModalActiveAccountSuccess';
import AnalyticsService from '@services/analytics/analytics';
import events from '@services/analytics/events';

const googleClientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const googleOAuthUrl = import.meta.env.VITE_GOOGLE_URL;
const googleCallbackActivateRedirectUrl = import.meta.env
  .VITE_GOOGLE_CALLBACK_ACTIVATE_REDIRECT_URL;

const errorActiveAccountMap = {
  Invalid: 'Token inválido',
  'Token expired': 'Token expirado',
  'CPF already used': 'CPF já utilizado',
  'Email already used': 'Email já utilizado',
  'Google user with different email': 'Usuário Google com email diferente',
};

const passwordHintStyle = {
  text: tv({
    base: 'text-xs font-regular text-neutral-600 flex gap-2 items-center',
    variants: {
      success: {
        true: 'text-success-900',
        false: '',
      },
    },
  }),
  icon: tv({
    base: 'bg-neutral-400 w-[12px] h-[12px] rounded-full',
    variants: {
      success: {
        true: 'bg-success-900',
        false: '',
      },
    },
  }),
};

export function ActivateAccountForm({ user }) {
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();
  const { setAccountData, accountData } = useActivateAccount();

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: '',
      familyName: '',
      email: '',
      cpf: '',
      password: '',
      confirmPassword: '',
    },
  });

  useEffect(() => {
    if (!user) {
      return;
    }

    setValue('email', user.email);
    setValue('firstName', user.firstName);
    setValue('familyName', user.familyName);
    accountData?.cpf && setValue('cpf', formatCpf(accountData.cpf));
    accountData?.password && setValue('password', accountData.password);
    accountData?.password && setValue('confirmPassword', accountData.password);
  }, [user, setValue, accountData]);

  const handleMouseHover = (value) => setIsHovered(value);

  async function onSubmit(data, event) {
    setIsLoading(true);
    setPersistedData();
    const buttonName = event.nativeEvent.submitter.name;
    if (buttonName === 'googleActivateAccount') {
      googleActivateAccount();
      return;
    }

    AnalyticsService.trackEvent(events.USER_ACTIVATION_REGULAR_BUTTON_CLICKED);

    try {
      await api.post(`/users/activate`, {
        token,
        firstName: data.firstName,
        familyName: data.familyName,
        email: data.email,
        cpf: data.cpf.replace(/\D/g, ''),
        password: data.password,
        googleToken: '',
      });
      setIsOpened(true);

      AnalyticsService.trackEvent(
        events.USER_ACTIVATION_SUCESS_MODAL_PAGE_VIEWED,
      );
    } catch (error) {
      alert(errorActiveAccountMap[error?.message] || error?.message);
    } finally {
      setIsLoading(false);
    }
  }

  function onModalClose() {
    AnalyticsService.trackEvent(
      events.USER_ACTIVATION_SUCESS_MODAL_BUTTON_CLICKED,
    );

    setIsOpened(false);
    navigate('/');
  }

  function googleActivateAccount() {
    AnalyticsService.trackEvent(events.USER_ACTIVATION_GOOGLE_BUTTON_CLICKED);

    const googleLoginUrl = `${googleOAuthUrl}?response_type=code&client_id=${googleClientId}&redirect_uri=${googleCallbackActivateRedirectUrl}&scope=email%20profile%20openid`;
    window.location.href = googleLoginUrl;
  }

  function setPersistedData() {
    setAccountData({
      firstName: watch('firstName'),
      familyName: watch('familyName'),
      email: watch('email'),
      cpf: watch('cpf'),
      password: watch('password'),
      token,
    });
  }

  function handleCpfChange(event) {
    const { value } = event.target;
    const formattedValue = formatCpf(value);
    setValue('cpf', formattedValue);
  }

  function formatCpf(value) {
    return value
      .replace(/\D/g, '')
      .slice(0, 11)
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  }

  const passwordValidation = validatePassword(watch('password'));
  const passwordValidateLength = passwordValidation?.errors?.minLength;
  const passwordValidateLowercase =
    passwordValidation?.errors?.lowerCase &&
    passwordValidation?.errors?.upperCase;
  const passwordValidateNumber = passwordValidation?.errors?.number;
  const passwordValidateSpecial = passwordValidation?.errors?.specialChar;

  return (
    <>
      <ModalActiveAccountSuccess open={isOpened} handleClose={onModalClose} />
      <div className="h-[100vh] w-[100%] bg-neutral-100">
        <ActivateAccountHeader />
        <div className="flex items-center justify-center gap-24 overflow-auto bg-background px-2 py-8">
          <div className="hidden h-[724px] w-[670px] rounded-[20px] bg-[url('/src/assets/bg-login.jpg')] bg-cover bg-center bg-no-repeat notebook:block" />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex h-[100%] max-w-[724px] flex-1 flex-col gap-8 overflow-auto rounded-md bg-white p-6 shadow-header">
              <div className="flex flex-col gap-2">
                <h1 className="text-primary-500 m-0 text-2xl font-semibold">
                  Ative sua conta
                </h1>
                <p className="m-0 text-sm text-neutral-800">
                  Preencha os dados necessários e comece a usar a book2b.{' '}
                </p>
              </div>
              <div className="grid grid-cols-12 gap-4">
                <Form.Group className="col-span-6">
                  <Form.Label>Nome</Form.Label>
                  <InputText
                    placeholder="Maria"
                    placeholderStyle="normal"
                    error={errors?.firstName?.message ?? ''}
                    {...register(`firstName`, {
                      required: {
                        message: 'Campo obrigatório',
                        value: true,
                      },
                      minLength: 3,
                    })}
                  />
                </Form.Group>
                <Form.Group className="col-span-6">
                  <Form.Label>Sobrenome</Form.Label>
                  <InputText
                    placeholder="da Silva"
                    placeholderStyle="normal"
                    error={errors?.familyName?.message ?? ''}
                    {...register(`familyName`, {
                      required: {
                        message: 'Campo obrigatório',
                        value: true,
                      },
                      minLength: 3,
                    })}
                  />
                </Form.Group>
                <Form.Group className="col-span-12">
                  <Form.Label>CPF</Form.Label>
                  <InputText
                    placeholder="001.001.002-12"
                    placeholderStyle="normal"
                    error={errors?.cpf?.message ?? ''}
                    {...register(`cpf`, {
                      required: {
                        message: 'Campo obrigatório',
                        value: true,
                      },
                      minLength: 3,
                      onChange: handleCpfChange,
                    })}
                  />
                </Form.Group>
                <Form.Group className="col-span-12">
                  <Form.Label>E-mail</Form.Label>
                  <InputText
                    placeholder="exemplo@exemplo.com"
                    placeholderStyle="normal"
                    error={errors?.email?.message ?? ''}
                    disabled
                    {...register(`email`, {
                      required: {
                        message: 'Campo obrigatório',
                        value: true,
                      },
                      minLength: 3,
                    })}
                  />
                </Form.Group>
                <Form.Group className="col-span-6">
                  <Form.Label>Senha</Form.Label>
                  <InputText
                    placeholder="************"
                    placeholderStyle="normal"
                    type="password"
                    error={errors?.password?.message ?? ''}
                    {...register(`password`, {
                      required: {
                        message: 'Campo obrigatório',
                        value: true,
                      },
                      minLength: 3,
                      validate: (value) =>
                        validatePassword(value).isValid || 'Senha inválida',
                    })}
                  />
                </Form.Group>
                <Form.Group className="col-span-6">
                  <Form.Label>Confirmar senha</Form.Label>
                  <InputText
                    placeholder="************"
                    type="password"
                    placeholderStyle="normal"
                    error={errors?.confirmPassword?.message ?? ''}
                    {...register(`confirmPassword`, {
                      required: {
                        message: 'Campo obrigatório',
                        value: true,
                      },
                      minLength: 3,
                      validate: (value) =>
                        value === watch('password') ||
                        'As senhas não coincidem',
                    })}
                  />
                </Form.Group>
                <Form.Group className="col-span-12">
                  <div className="group relative flex flex-col gap-2">
                    <p
                      className={`m-0 flex cursor-pointer items-center gap-1 text-xs font-bold ${passwordValidation.isValid ? 'text-success-900' : 'text-neutral-800'}`}
                    >
                      <RiInformationLine size={16} />
                      Requisitos para nova senha
                    </p>
                    <ul className="absolute top-6 m-0 hidden list-none flex-col gap-2 rounded-md bg-white p-4 text-xs text-neutral-600 shadow-header group-hover:flex">
                      <li
                        className={passwordHintStyle.text({
                          success: passwordValidateLength,
                        })}
                      >
                        {' '}
                        <div
                          className={passwordHintStyle.icon({
                            success: passwordValidateLength,
                          })}
                        ></div>{' '}
                        Mínimo de 8 caracteres - quanto mais, melhor
                      </li>
                      <li
                        className={passwordHintStyle.text({
                          success: passwordValidateLowercase,
                        })}
                      >
                        <div
                          className={passwordHintStyle.icon({
                            success: passwordValidateLowercase,
                          })}
                        ></div>
                        Pelo menos um caractere minúsculo e um maiúsculo
                      </li>
                      <li
                        className={passwordHintStyle.text({
                          success:
                            passwordValidateNumber && passwordValidateSpecial,
                        })}
                      >
                        <div
                          className={passwordHintStyle.icon({
                            success:
                              passwordValidateNumber && passwordValidateSpecial,
                          })}
                        ></div>
                        Pelo menos um número, símbolo ou caractere especial
                      </li>
                    </ul>
                  </div>
                </Form.Group>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex h-[48px]">
                  <Button
                    className="flex-1 px-4"
                    label="Ativar conta"
                    type="submit"
                    disabled={isLoading}
                  />
                </div>
                <div className="flex h-[48px]">
                  <button
                    type="submit"
                    className="m-0 flex h-[48px] flex-1 justify-center border border-[#eb0045] bg-white px-4 font-semibold text-[#eb0045] hover:border-0 hover:text-white"
                    onMouseEnter={() => handleMouseHover(true)}
                    onMouseLeave={() => handleMouseHover(false)}
                    name="googleActivateAccount"
                  >
                    <GoogleLogo height={16} width={16} allWhite={isHovered} />

                    <span className="ml-2">Ativar conta com Google</span>
                  </button>
                </div>
                <div className="flex justify-center">
                  <p className="m-0 max-w-[304px] text-center text-xs leading-5 text-neutral-600">
                    Ao me inscrever, concordo com os{' '}
                    <a
                      href="#"
                      className="text-xs font-normal text-neutral-600 underline"
                    >
                      Termos de Uso
                    </a>{' '}
                    e a{' '}
                    <a
                      href="#"
                      className="text-xs font-normal text-neutral-600 underline"
                    >
                      Política de Privacidade
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
