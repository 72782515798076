import { useState, useEffect, useCallback } from 'react';
import { BiSearch } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { Badge } from '@components/Badge';
import { DropdownThreeDots } from '@components/dropdown-three-dots';
import { Form } from '@components/new-components/form';
import { InputV2 } from '@components/new-components/input-v2';
import { SelectV2 } from '@components/new-components/select-v2';
import PageHeader from '@components/page-header';
import {
  SELECT_OPTIONS_ADMIN,
  SELECT_OPTIONS_BUYER_ADMIN,
} from '@data/roles.data';
import api from '@services/api';
import { useAuthentication } from '@stores/authentication';
import { Role } from '@system/acl';
import LoadingAnimation from '../../components/LoadingAnimation';
import { PagePath } from '../../pages-config';
import { ModalInviteNewUser } from './components/ModalInviteNewUser';
import { ModalWithTwoButtons } from '@components/ModalWithTwoButtons';
import analyticsService from '@services/analytics/analytics';
import events from '@services/analytics/events';

export default function AdminUsuarios() {
  const [loading, setLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [users, setUsers] = useState([]);
  const [userPagination, setUserPagination] = useState({
    page: 1,
    total: 0,
    totalPages: 0,
  });
  const [agencies, setAgencies] = useState([]);

  const [userSearch, setUserSearch] = useState('');
  const [agencyIdSearch, setAgencyIdSearch] = useState('');
  const [roleIdSearch, setRoleIdSearch] = useState('');

  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState({
    title: '',
    message: '',
    buttonMessage: '',
    callback: null,
  });

  const { session } = useAuthentication();
  const navigate = useNavigate();

  const init = useCallback(async () => {
    setLoading(true);
    try {
      const [{ data: resAgencies }, { data: resUsers }] = await Promise.all([
        api.get('agencies'),
        api.get('users', {
          params: {
            page: 1,
          },
        }),
      ]);

      setAgencies(
        resAgencies?.sort((a, b) =>
          a.razaosocial.localeCompare(b.razaosocial),
        ) ?? [],
      );
      setUsers(resUsers.result);
      setUserPagination({
        page: resUsers.page,
        total: resUsers.total,
        totalPages: resUsers.totalPages,
      });
    } catch (error) {
      console.error(error);
      alert(error?.message ?? 'Ocorreu um erro ao carregar os dados.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
    analyticsService.trackEvent(events.USER_LIST_PAGE_VIEWED);
  }, [init]);

  async function fetchUsers(page = 1, filters = {}) {
    setLoading(true);
    try {
      const { data } = await api.get('users', {
        params: {
          page,
          ...filters,
        },
      });
      setUsers(page === 1 ? data.result : [...users, ...data.result]);
      setUserPagination({
        page: data.page,
        total: data.total,
        totalPages: data.totalPages,
      });
    } catch (error) {
      console.error('Error fetching users:', error);
      alert('Ocorreu um erro ao buscar os usuários.');
    } finally {
      setLoading(false);
    }
  }

  async function nextPage(page) {
    analyticsService.trackEvent(events.USER_LIST_EXPAND_RESULTS_BUTTON_CLICKED);

    if (page > userPagination.totalPages) {
      return;
    }
    await fetchUsers(page, {
      ...(userSearch && { q: userSearch }),
      ...(agencyIdSearch && { agencyId: agencyIdSearch }),
      ...(roleIdSearch && { roleId: roleIdSearch }),
    });
  }

  async function handleFilterUsers() {
    await fetchUsers(1, {
      ...(userSearch && { q: userSearch }),
      ...(agencyIdSearch && { agencyId: agencyIdSearch }),
      ...(roleIdSearch && { roleId: roleIdSearch }),
    });
  }

  const SELECT_OPTIONS_COMPANIES = agencies
    ?.filter((data) => {
      return (
        session.role === Role.Administrador ||
        (session.role === Role.CompradorAdministrador &&
          data.nome_fantasia === session.agency.nomeFantasia)
      );
    })
    .map((data) => ({
      value: data.id,
      label: data.nome_fantasia,
    }));

  function inviteUser() {
    analyticsService.trackEvent(events.USER_LIST_INVITE_BUTTON_CLICKED);

    setOpenModal(true);

    analyticsService.trackEvent(events.USER_LIST_INVITE_MODAL_PAGE_VIEWED);
  }

  function handleEditUser(id) {
    analyticsService.trackEvent(events.USER_LIST_ACTIONS_EDIT_BUTTON_CLICKED);

    navigate(PagePath.AdminUsuarioEdit.replace(':id', id));
  }

  async function handleUpdateUserAgencyOrRole(userId, data) {
    try {
      await api.patch(`users/${userId}`, {
        email: data.email,
        ...(data.agencyId && { agencyId: data.agencyId }),
        ...(data.roleId && { status: data.roleId }),
      });
    } catch (error) {
      console.error('Error updating user agency or role:', error);
      alert('Ocorreu um erro ao atualizar a empresa ou perfil do usuário.');
    }
  }

  async function handleUpdateUserStatus(action, data) {
    setIsActionLoading(true);
    try {
      await api.patch(`users/${data.id}`, {
        userActive: action === 'activate' ? 1 : 0,
        email: data.email,
      });
      const userUpdated = users.map((user) => {
        if (user.id === data.id) {
          return {
            ...user,
            userActive: action === 'activate' ? 1 : 0,
          };
        }
        return user;
      });
      setUsers(userUpdated);
    } catch (error) {
      console.error('Error updating user status:', error);
      alert('Ocorreu um erro ao atualizar o status do usuário.');
    } finally {
      setIsActionLoading(false);
    }
  }

  async function handleResendInvite(email) {
    analyticsService.trackEvent(
      events.USER_LIST_RESEND_CONFIRMATION_MODAL_BUTTON_CLICKED,
    );

    setIsActionLoading(true);
    try {
      await api.post(`users/resend-invitation`, {
        email,
      });
    } catch (error) {
      console.error('Error resending invite:', error);
      alert('Ocorreu um erro ao reenviar o convite.');
    } finally {
      setIsActionLoading(false);
    }
  }

  async function handleDeleteInvite(id) {
    analyticsService.trackEvent(
      events.USER_LIST_EXCLUSION_CONFIRMATION_MODAL_BUTTON_CLICKED,
    );

    setIsActionLoading(true);
    try {
      await api.delete(`users/invite/${id}`);
      setUsers(users.filter((user) => user.id !== id));
    } catch (error) {
      console.error('Error deleting invite:', error);
      alert('Ocorreu um erro ao excluir o convite.');
    } finally {
      setIsActionLoading(false);
    }
  }

  const userStatusOptions = (status) => {
    if (status == '0') {
      return [
        {
          label: 'Editar',
          onClick: (data) => handleEditUser(data.id),
        },
        {
          label: 'Ativar usuário',
          onClick: (data) => {
            setConfirmModalData({
              title: 'Ativar usuário',
              message:
                'Ao ativar este usuário, ele terá novamente acesso às funcionalidades da plataforma e suas permissões serão restauradas.',
              buttonMessage: 'Ativar usuário',
              callback: () => handleUpdateUserStatus('activate', data),
            });
            setConfirmModalIsOpen(true);
          },
        },
      ];
    }

    if (status == '1') {
      return [
        {
          label: 'Editar',
          onClick: (data) => handleEditUser(data.id),
        },
        {
          label: 'Inativar',
          onClick: (data) => {
            setConfirmModalData({
              title: 'Inativar usuário',
              message:
                'Ao inativar este usuário, ele será removido de todas as atividades e não terá mais acesso à sua conta. Essa ação pode ser revertida posteriormente.',
              buttonMessage: 'Inativar usuário',
              callback: () => handleUpdateUserStatus('inactivate', data),
            });
            setConfirmModalIsOpen(true);
          },
        },
      ];
    }

    return [
      {
        label: 'Excluir Convite',
        onClick: (data) => {
          analyticsService.trackEvent(
            events.USER_LIST_ACTIONS_EXCLUSION_BUTTON_CLICKED,
          );

          setConfirmModalData({
            title: 'Confirmar exclusão de convite',
            message:
              'Esta ação irá remover o convite enviado ao novo usuário. Caso necessário, será preciso gerar e enviar um novo convite.',
            buttonMessage: 'Excluir convite',
            callback: () => handleDeleteInvite(data.id),
          });
          setConfirmModalIsOpen(true);

          analyticsService.trackEvent(
            events.USER_LIST_EXCLUSION_CONFIRMATION_MODAL_PAGE_VIEWED,
          );
        },
      },
      {
        label: 'Reenviar Convite',
        onClick: (data) => {
          analyticsService.trackEvent(
            events.USER_LIST_ACTIONS_RESEND_BUTTON_CLICKED,
          );

          setConfirmModalData({
            title: 'Confirmar reenvio de convite',
            message: `Deseja reenviar o convite para ${data.email}? O usuário receberá um novo link de cadastro e ativação da conta.`,
            buttonMessage: 'Reenviar convite',
            callback: () => handleResendInvite(data.email),
          });
          setConfirmModalIsOpen(true);
        },
      },
    ];
  };

  function closeModal() {
    setOpenModal(false);
    init();
  }

  const userStatusBadge = (status) => {
    if (status == '0') {
      return <Badge color="neutral">Inativo</Badge>;
    }

    if (status == '1') {
      return <Badge color="success">Ativo</Badge>;
    }

    return <Badge color="danger">Pendente</Badge>;
  };

  const confirmModalAction = () => {
    if (confirmModalData.callback) {
      confirmModalData.callback();
      setConfirmModalIsOpen(false);

      setConfirmModalData({
        title: '',
        message: '',
        buttonMessage: '',
        callback: null,
      });
    }
  };

  return (
    <>
      <ModalInviteNewUser
        open={openModal}
        onClose={closeModal}
        agencies={SELECT_OPTIONS_COMPANIES}
      />

      {loading && <LoadingAnimation />}

      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Configurações', link: PagePath.Admin },
          { label: 'Usuários', link: PagePath.AdminUsuarios },
        ]}
        title="Usuários"
        buttonClick={() => inviteUser()}
        button="+ Convidar novos usuários"
      />

      <div className="mb-5 grid grid-cols-6 grid-rows-1 gap-4 rounded-md bg-white p-4">
        <div className="col-span-2">
          <Form.Group>
            <Form.Label>Buscar usuário</Form.Label>
            <InputV2
              onChange={(event) => setUserSearch(event.target.value)}
              value={userSearch}
              placeholder="Digite o nome ou e-mail do cliente"
              placeholderStyle="normal"
            />
          </Form.Group>
        </div>
        <div className="col-span-2 col-start-3">
          <Form.Group>
            <Form.Label>Empresa</Form.Label>
            <SelectV2
              placeholder="Selecione"
              placeholderStyle="normal"
              onChange={(event) => setAgencyIdSearch(event.target.value)}
              value={agencyIdSearch}
              options={[
                { value: '', label: 'Selecione' },
                ...SELECT_OPTIONS_COMPANIES,
              ]}
            />
          </Form.Group>
        </div>
        <div className="col-span-2 flex w-full items-end justify-between gap-3">
          <Form.Group className="w-full">
            <Form.Label>Perfil</Form.Label>
            <div className="flex gap-3">
              <SelectV2
                placeholder="Selecione"
                placeholderStyle="normal"
                value={roleIdSearch}
                onChange={(event) => setRoleIdSearch(event.target.value)}
                options={[
                  { value: '', label: 'Selecione' },
                  ...(session.role === Role.Administrador
                    ? SELECT_OPTIONS_ADMIN
                    : SELECT_OPTIONS_BUYER_ADMIN),
                ]}
              />
              <span
                role="button"
                onClick={handleFilterUsers}
                className="group !mt-1 flex h-9 w-10 items-center justify-center rounded-full border border-solid border-secondary bg-white hover:bg-secondary"
              >
                <BiSearch
                  size={18}
                  className="text-xs text-secondary transition-none group-hover:text-white"
                />
              </span>
            </div>
          </Form.Group>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="btnTable">
          {/*
              <select onChange={(e) => changeLimit(e.target.value)} className={dataTotal > 25 ? 'selectLimit' : 'hidden'}>
                  <option value="25">25</option>
                  {dataTotal > 50 ? <option value="50">50</option> : undefined}
                  {dataTotal > 100 ? <option value="100">100</option> : undefined}
                  {dataTotal > 250 ? <option value="250">250</option> : undefined}
                  {dataTotal > 500 ? <option value="500">500</option> : undefined}
              </select>
              <div className="tdtoal">{startQuery+1} - {newStartQuery}  de  {formatNumber(dataTotal)}</div>
              <button type="button" className="btn btn-light m-1" onClick={() => lessData()} disabled={startQuery==0 ? true : false}><BsChevronLeft /></button>
              <button type="button" className="btn btn-light m-1" onClick={() => moreData()} disabled={newStartQuery==dataTotal ? true : false}><BsChevronRight /></button>
              <button onClick={() => onTableChange()} type="button" className="btn btn-light m-1 nomob">{tdShort ? <RiExpandLeftRightFill title="Expandir" /> : <RiContractLeftRightFill title="Contrair" />}</button>*/}
        </div>
      </div>

      <div className="mt-4 flex flex-col items-center gap-5 rounded-md bg-white p-4">
        <div className="m-0 flex w-full">
          <h4 className="m-0 text-xl text-primary">Lista de usuários</h4>
        </div>
        <div className="m-0 w-full rounded-md border border-solid border-neutral-300 px-3 py-2.5">
          <table className="w-full border-collapse">
            <thead className="h-12 border-x-0 border-b border-t-0 border-solid border-primary !pb-3">
              <tr>
                <th
                  className="pl-3 text-body font-semibold text-primary"
                  scope="col"
                >
                  Nome
                </th>
                <th
                  className="!border-solid text-body font-semibold text-primary"
                  scope="col"
                >
                  E-mail
                </th>
                <th
                  className="!border-solid text-body font-semibold text-primary"
                  scope="col"
                >
                  Perfil
                </th>
                <th
                  className="!border-solid text-body font-semibold text-primary"
                  scope="col"
                >
                  Empresa
                </th>
                <th
                  className="!border-solid text-body font-semibold text-primary"
                  scope="col"
                >
                  Status
                </th>
                <th
                  className="!border-solid text-body font-semibold text-primary"
                  scope="col"
                >
                  Ações
                </th>
              </tr>
            </thead>
            <tbody className="m-0 divide-y divide-solid divide-neutral-300 p-0">
              {users.map((user, index) => (
                <tr key={index} className="h-[72px] hover:bg-secondary-100">
                  <td className="pl-3 text-body text-neutral-800">
                    {user.firstName} {user.familyName}
                  </td>
                  <td className="text-body text-neutral-800">{user.email}</td>
                  <td className="pr-4 text-center">
                    <SelectV2
                      options={
                        session.role === Role.Administrador
                          ? SELECT_OPTIONS_ADMIN
                          : SELECT_OPTIONS_BUYER_ADMIN
                      }
                      value={user.status}
                      onChange={(value) => {
                        handleUpdateUserAgencyOrRole(user.id, {
                          email: user.email,
                          roleId: value.target.value,
                        });

                        analyticsService.trackEvent(
                          events.USER_LIST_CHANGE_PROFILE_INPUT_CLICKED,
                        );
                      }}
                    />
                  </td>
                  <td className="pr-4">
                    <SelectV2
                      options={SELECT_OPTIONS_COMPANIES}
                      value={user.agenciaCliente}
                      onChange={(value) => {
                        handleUpdateUserAgencyOrRole(user.id, {
                          email: user.email,
                          agencyId: value.target.value,
                        });

                        analyticsService.trackEvent(
                          events.USER_LIST_CHANGE_PROFILE_INPUT_CLICKED,
                        );
                      }}
                    />
                  </td>
                  <td className="text-center">
                    {userStatusBadge(user.userActive)}
                  </td>
                  <td>
                    <DropdownThreeDots
                      options={userStatusOptions(user.userActive)}
                      data={user}
                      isLoading={isActionLoading}
                      onClicked={() =>
                        analyticsService.trackEvent(
                          events.USER_LIST_ACTIONS_BUTTON_CLICKED,
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {users.length !== userPagination.total && users.length !== 0 && (
          <div className="py-2 text-center">
            <span
              role="button"
              className="text-body font-semibold text-secondary"
              onClick={() => nextPage(userPagination.page + 1)}
            >
              Ver mais resultados
            </span>
          </div>
        )}
      </div>
      <ModalWithTwoButtons
        isOpen={confirmModalIsOpen}
        onClose={() => setConfirmModalIsOpen(false)}
        onConfirm={() => confirmModalAction()}
        isLoading={isActionLoading}
        title={confirmModalData.title}
        message={confirmModalData.message}
        leftButtonTitle="Fechar"
        rightButtonTitle={confirmModalData.buttonMessage}
      />
    </>
  );
}
