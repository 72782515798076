import { useState, useCallback, useRef, useEffect } from 'react';

const ALERT_TYPES = {
  SUCCESS: 'alert-success',
  ERROR: 'alert-danger',
  WARNING: 'alert-warning',
  INFO: 'alert-info',
};

const DEFAULT_TIMEOUT = 3000;
const MIN_TIMEOUT = 1000;

export function useAlert(defaultTimeout = DEFAULT_TIMEOUT) {
  const [alert, setAlert] = useState({ type: '', title: '', message: '' });
  const timeoutRef = useRef(null);
  const alertQueue = useRef([]);

  const clearCurrentAlert = useCallback(() => {
    setAlert({ type: '', title: '', message: '' });
  }, []);

  const clearTimeout = useCallback(() => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  const processNextAlert = useCallback(() => {
    if (alertQueue.current.length > 0) {
      const nextAlert = alertQueue.current.shift();
      setAlert(nextAlert.alert);
      timeoutRef.current = window.setTimeout(() => {
        clearCurrentAlert();
        processNextAlert();
      }, nextAlert.timeout);
    }
  }, [clearCurrentAlert]);

  useEffect(() => {
    return () => {
      clearTimeout();
      alertQueue.current = [];
    };
  }, [clearTimeout]);

  const showAlert = useCallback(
    (type, title = '', message = '', customTimeout = defaultTimeout) => {
      const alertType = type.startsWith('alert-')
        ? type
        : ALERT_TYPES[type.toUpperCase()] || ALERT_TYPES.INFO;

      const timeout = Math.max(customTimeout, MIN_TIMEOUT);

      if (!alert.type) {
        clearTimeout();
        setAlert({ type: alertType, title, message });
        timeoutRef.current = window.setTimeout(() => {
          clearCurrentAlert();
          processNextAlert();
        }, timeout);
      } else {
        alertQueue.current.push({
          alert: { type: alertType, title, message },
          timeout,
        });
      }
    },
    [
      alert.type,
      clearCurrentAlert,
      clearTimeout,
      defaultTimeout,
      processNextAlert,
    ],
  );

  const success = useCallback(
    (message, title = 'Sucesso!', timeout) =>
      showAlert(ALERT_TYPES.SUCCESS, title, message, timeout),
    [showAlert],
  );

  const error = useCallback(
    (message, title = 'Erro!', timeout) =>
      showAlert(ALERT_TYPES.ERROR, title, message, timeout),
    [showAlert],
  );

  const warning = useCallback(
    (message, title = 'Atenção!', timeout) =>
      showAlert(ALERT_TYPES.WARNING, title, message, timeout),
    [showAlert],
  );

  const info = useCallback(
    (message, title = 'Info', timeout) =>
      showAlert(ALERT_TYPES.INFO, title, message, timeout),
    [showAlert],
  );

  const close = useCallback(() => {
    clearTimeout();
    clearCurrentAlert();
    processNextAlert();
  }, [clearTimeout, clearCurrentAlert, processNextAlert]);

  return {
    alert,
    showAlert,
    success,
    error,
    warning,
    info,
    close,
  };
}
