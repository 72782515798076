import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { InputV2 } from '../input-v2';

const SelectV2 = forwardRef(
  ({ options, onChange, placeholder, value, ...props }, ref) => {
    const showLabel = value && options.find((opt) => opt.value === value);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState({
      label: showLabel?.label ?? '',
      value: showLabel?.value ?? '',
    });
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);

    useImperativeHandle(ref, () => inputRef.current);

    const handleSelect = (label, value) => {
      setSelectedValue({ label, value });
      setIsOpen(false);

      const event = new Event('input', { bubbles: true });
      inputRef.current.value = value;
      inputRef.current.dispatchEvent(event);
      if (onChange) {
        onChange(event);
      }
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    useEffect(() => {
      if (![null, undefined, ''].includes(value)) {
        const initialOption = options.find((opt) => opt.value === value);
        if (initialOption) {
          setSelectedValue({
            label: initialOption.label,
            value: initialOption.value,
          });
        }
      } else {
        setSelectedValue({
          label: '',
          value: '',
        });
      }
    }, [value, options]);

    return (
      <div className="relative inline-block !w-full" ref={dropdownRef}>
        <input
          {...props}
          type="hidden"
          ref={inputRef}
          value={selectedValue.value}
        />
        <InputV2
          {...props}
          type="text"
          onClick={() => setIsOpen(!isOpen)}
          readOnly
          className={`${selectedValue.label ? '!border-neutral-800' : ''} w-full cursor-pointer`}
          placeholder={selectedValue.label ? '' : placeholder}
        />
        <div
          className="pointer-events-none absolute inset-0 flex items-center px-2 text-sm"
          dangerouslySetInnerHTML={{ __html: selectedValue.label }}
        ></div>
        <div className="absolute right-4 top-3 z-20 h-4 w-4">
          <RiArrowDropDownLine className="!text-neutral-800" size={20} />
        </div>
        {isOpen && (
          <div className="absolute left-0 right-0 top-full z-50 max-h-[240px] divide-x-0 divide-y divide-solid !divide-neutral-300 overflow-auto rounded-md border border-solid border-neutral-300 bg-white px-2">
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleSelect(option.label, option.value)}
                className="max-h-[52px] cursor-pointer truncate p-2 text-sm hover:bg-neutral-100"
                dangerouslySetInnerHTML={{ __html: option.label }}
              />
            ))}
          </div>
        )}
      </div>
    );
  },
);

SelectV2.displayName = 'SelectV2';

export { SelectV2 };
