/* eslint-disable no-redeclare */
/* eslint-disable no-var */
import '@components/Reserva.css';
import { useEffect, useState } from 'react';
import { MdOutlineStar } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { produce } from 'immer';
import api from '@services/api';
import { useBookings } from '@stores/bookings';
import { FlightClassCategory, PassengerType } from '@system/enums';

export default function AirplaneReservation() {
  const { methods, travelers, reservedSeats } = useBookings();

  const [loading, setLoading] = useState(false);
  const {
    state: { charterId: id },
  } = useLocation();
  const [selectClass] = useState('primeiraclasse');
  const [primeiraclasseFileiras, setPrimeiraclasseFileiras] = useState([]);
  const [primeiraclasseColunas, setPrimeiraclasseColunas] = useState([]);
  const [totalPrimeiraclasseFileiras, setTotalPrimeiraclasseFileiras] =
    useState('');
  const [totalPrimeiraclasseColunas, setTotalPrimeiraclasseColunas] =
    useState('');
  const [premiumFileiras, setPremiumFileiras] = useState([]);
  const [premiumColunas, setPremiumColunas] = useState([]);
  const [totalPremiumFileiras, setTotalPremiumFileiras] = useState('');
  const [totalPremiumColunas, setTotalPremiumColunas] = useState('');
  const [executivaFileiras, setExecutivaFileiras] = useState([]);
  const [executivaColunas, setExecutivaColunas] = useState([]);
  const [totalExecutivaFileiras, setTotalExecutivaFileiras] = useState('');
  const [totalExecutivaColunas, setTotalExecutivaColunas] = useState('');
  const [economicaFileiras, setEconomicaFileiras] = useState([]);
  const [economicaColunas, setEconomicaColunas] = useState([]);
  const [totalEconomicaFileiras, setTotalEconomicaFileiras] = useState('');
  const [totalEconomicaColunas, setTotalEconomicaColunas] = useState('');
  const [minFileiras] = useState([
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
  ]);
  const [corredoresPrimC, setCorredoresPrimC] = useState([]);
  const [corredoresPremium, setCorredoresPremium] = useState([]);
  const [corredoresExecutiva, setCorredoresExecutiva] = useState([]);
  const [corredoresEconomica, setCorredoresEconomica] = useState([]);
  const [saidaEmergPrimC, setSaidaEmergPrimC] = useState([]);
  const [saidaEmergPremium, setSaidaEmergPremium] = useState([]);
  const [saidaEmergExecutiva, setSaidaEmergExecutiva] = useState([]);
  const [saidaEmergEconomica, setSaidaEmergEconomica] = useState([]);
  const [totalFilPC, setTotalFilPC] = useState(0);
  const [nomenPC, setNomenPC] = useState('');
  const [nomenPR, setNomenPR] = useState('');
  const [nomenEX, setNomenEX] = useState('');
  const [nomenEC, setNomenEC] = useState('');
  const [labelColumn, setLabelColumn] = useState([]);

  useEffect(() => {
    loadPage();
    methods.getReservedSeats(id);
  }, []);

  function loadPage() {
    setLoading(true);

    api
      .get(`/charters/${id}/get/informations/table/prices`)
      .then((response) => {
        if (response.data.length > 0) {
          methods.updateTablePrice({
            [FlightClassCategory.FirstClass]: {
              adt: response.data[0]['pcadt'],
              chd: response.data[0]['pcchd'],
              inf: response.data[0]['pcinf'],
              tax: response.data[0]['taxa_embarque'],
            },
            [FlightClassCategory.Premium]: {
              adt: response.data[0]['pradt'],
              chd: response.data[0]['prchd'],
              inf: response.data[0]['prinf'],
              tax: response.data[0]['taxa_embarque'],
            },
            [FlightClassCategory.Business]: {
              adt: response.data[0]['exadt'],
              chd: response.data[0]['exchd'],
              inf: response.data[0]['exinf'],
              tax: response.data[0]['taxa_embarque'],
            },
            [FlightClassCategory.Economy]: {
              adt: response.data[0]['ecadt'],
              chd: response.data[0]['ecchd'],
              inf: response.data[0]['ecinf'],
              tax: response.data[0]['taxa_embarque'],
            },
          });
        }
      })
      .catch((error) => alert(error.message));
  }

  const fetchRoutesScalesConnections = async () => {
    try {
      const routesScalesConnections = await api.get(
        `/charters/${id}/get/informations/routes/scales/connections`,
      );

      if (routesScalesConnections.data.length > 0) {
        setNomenPC(routesScalesConnections.data[0]['nomen_pc']);
        setNomenPR(routesScalesConnections.data[0]['nomen_pr']);
        setNomenEX(routesScalesConnections.data[0]['nomen_ex']);
        setNomenEC(routesScalesConnections.data[0]['nomen_ec']);

        carregaPagina(routesScalesConnections.data[0]['id_aeronave']);

        api
          .get(
            `/label-columns/${routesScalesConnections.data[0]['id_aeronave']}`,
          )
          .then((response) => {
            if (response.data.length > 0) {
              setLabelColumn(response.data);
            }
          })
          .catch((error) => alert(error));

        setLoading(false);
      }
    } catch (error) {
      alert('Error 1: ' + error.message);
    }
  };

  useEffect(() => {
    fetchRoutesScalesConnections();
  }, [id]);

  function carregaPagina(idAeronave) {
    const categorias = ['primeiraclasse', 'premium', 'executiva', 'economica'];
    const colunasMap = {
      primeiraclasse: setPrimeiraclasseColunas,
      premium: setPremiumColunas,
      executiva: setExecutivaColunas,
      economica: setEconomicaColunas,
    };
    const fileirasMap = {
      primeiraclasse: setPrimeiraclasseFileiras,
      premium: setPremiumFileiras,
      executiva: setExecutivaFileiras,
      economica: setEconomicaFileiras,
    };
    const totalColunasMap = {
      primeiraclasse: setTotalPrimeiraclasseColunas,
      premium: setTotalPremiumColunas,
      executiva: setTotalExecutivaColunas,
      economica: setTotalEconomicaColunas,
    };
    const totalFileirasMap = {
      primeiraclasse: setTotalPrimeiraclasseFileiras,
      premium: setTotalPremiumFileiras,
      executiva: setTotalExecutivaFileiras,
      economica: setTotalEconomicaFileiras,
    };

    const fetchData = (categoria, filter, setFunction, setTotalFunction) => {
      api
        .get('/seat/filter', { params: filter })
        .then((response) => {
          if (response.data.length > 0) {
            setFunction(response.data);
            setTotalFunction(response.data.length);
          } else {
            setFunction([]);
            setTotalFunction('');
          }
        })
        .catch((error) =>
          alert(`Error loading ${categoria}: ${error.message}`),
        );
    };

    categorias.forEach((categoria) => {
      // Fetch fileiras
      fetchData(
        categoria,
        {
          filters: {
            idAeronave,
            categoria,
            fileira: 0,
            orderBy: 'fileira',
          },
        },
        fileirasMap[categoria],
        totalFileirasMap[categoria],
      );

      // Fetch colunas
      fetchData(
        categoria,
        {
          filters: {
            idAeronave,
            categoria,
            coluna: true,
            orderBy: 'coluna',
          },
        },
        colunasMap[categoria],
        totalColunasMap[categoria],
      );
    });

    carregaCorredores(idAeronave);
  }

  function carregaCorredores(idAeronave) {
    const classes = [
      { classe: 'primeiraClasse', setCorredores: setCorredoresPrimC },
      { classe: 'premium', setCorredores: setCorredoresPremium },
      { classe: 'executiva', setCorredores: setCorredoresExecutiva },
      { classe: 'economica', setCorredores: setCorredoresEconomica },
    ];

    const fetchCorredores = (classe, setFunction) => {
      const filter = {
        filters: {
          idAeronave,
          classe,
          orderBy: 'coluna',
        },
      };

      api
        .get('/aisle/filter', { params: filter })
        .then((response) => {
          if (response.data.length > 0) {
            setFunction(response.data);
          }
        })
        .catch((error) =>
          console.error(
            `Error loading corredores for ${classe}:`,
            error.message,
          ),
        );
    };

    classes.forEach(({ classe, setCorredores }) => {
      fetchCorredores(classe, setCorredores);
    });

    carregaSaidasEmergencia(idAeronave);
  }

  function carregaSaidasEmergencia(idAeronave) {
    const classes = [
      {
        classe: 'primeiraClasse',
        setSaida: setSaidaEmergPrimC,
        saidaKey: 'saidaEmergPrimC',
      },
      {
        classe: 'premium',
        setSaida: setSaidaEmergPremium,
        saidaKey: 'saidaEmergPremium',
      },
      {
        classe: 'executiva',
        setSaida: setSaidaEmergExecutiva,
        saidaKey: 'saidaEmergExecutiva',
      },
      {
        classe: 'economica',
        setSaida: setSaidaEmergEconomica,
        saidaKey: 'saidaEmergEconomica',
      },
    ];

    const fetchSaidaEmergencia = (classe, setFunction, saidaKey) => {
      const filter = {
        filters: {
          idAeronave,
          classe,
          orderBy: 'fileira',
        },
      };

      api
        .get('/emergency-exits/filter', { params: filter })
        .then((response) => {
          if (response.data.length > 0) {
            setFunction(response.data);
            methods.setSaidas({ [saidaKey]: response.data });
          }
        })
        .catch((error) =>
          console.error(
            `Error loading emergency exits for ${classe}:`,
            error.message,
          ),
        );
    };

    classes.forEach(({ classe, setSaida, saidaKey }) => {
      fetchSaidaEmergencia(classe, setSaida, saidaKey);
    });
  }

  function formatLabelColumn(ncolumn, nclass) {
    for (let i = 0; i <= labelColumn.length - 1; i++) {
      if (
        labelColumn[i]['class'] == nclass &&
        labelColumn[i]['column'] == ncolumn
      ) {
        return labelColumn[i]['label'];
      }
    }
    return ncolumn;
  }

  function totalizadorFileiras() {
    let a = 0;
    let b = 0;
    let c = 0;
    let d = 0;
    if (totalPrimeiraclasseFileiras) {
      a = totalPrimeiraclasseFileiras;
    }
    if (totalPremiumFileiras) {
      b = totalPremiumFileiras;
    }
    if (totalExecutivaFileiras) {
      c = totalExecutivaFileiras;
    }
    if (totalEconomicaFileiras) {
      d = totalEconomicaFileiras;
    }
    let x = parseInt(a) + parseInt(b) + parseInt(c) + parseInt(d);
    return x;
  }

  function totalizadorColunas() {
    let a = 0;
    let b = 0;
    let c = 0;
    let d = 0;
    if (totalPrimeiraclasseColunas) {
      a = totalPrimeiraclasseColunas;
    }
    if (totalPremiumColunas) {
      b = totalPremiumColunas;
    }
    if (totalExecutivaColunas) {
      c = totalExecutivaColunas;
    }
    if (totalEconomicaColunas) {
      d = totalEconomicaColunas;
    }
    let x = parseInt(a) + parseInt(b) + parseInt(c) + parseInt(d);
    return x;
  }

  function contFileiraPremium(x) {
    let y = 0;
    if (totalPrimeiraclasseFileiras) {
      y = totalPrimeiraclasseFileiras;
    }
    return parseInt(x) + parseInt(y);
  }

  function contFileiraExecutiva(x) {
    let a = 0;
    let b = 0;
    if (totalPrimeiraclasseFileiras) {
      a = totalPrimeiraclasseFileiras;
    }
    if (totalPremiumFileiras) {
      b = totalPremiumFileiras - 1;
    }
    return parseInt(x) + parseInt(a) + parseInt(b);
  }

  function contFileiraEconomica(x) {
    let a = 0;
    let b = 0;
    let c = 0;
    if (totalPrimeiraclasseFileiras) {
      a = totalPrimeiraclasseFileiras;
    }
    if (totalPremiumFileiras) {
      b = totalPremiumFileiras - 1;
    }
    if (totalExecutivaFileiras) {
      c = totalExecutivaFileiras - 1;
    }
    return parseInt(x) + parseInt(a) + parseInt(b) + parseInt(c);
  }

  function checkCorredor(x, y) {
    if (y == 'primeiraClasse') {
      for (var i = 0; i < corredoresPrimC.length; ++i) {
        if (corredoresPrimC[i]['column'] == x) {
          return true;
        }
      }
      return false;
    }
    if (y == 'premium') {
      for (var i = 0; i < corredoresPremium.length; ++i) {
        if (corredoresPremium[i]['column'] == x) {
          return true;
        }
      }
      return false;
    }
    if (y == 'executiva') {
      for (var i = 0; i < corredoresExecutiva.length; ++i) {
        if (corredoresExecutiva[i]['column'] == x) {
          return true;
        }
      }
      return false;
    }
    if (y == 'economica') {
      for (var i = 0; i < corredoresEconomica.length; ++i) {
        if (corredoresEconomica[i]['column'] == x) {
          return true;
        }
      }
      return false;
    }
  }

  function checkSaidaEmerg(x, y) {
    if (y == 'primeiraClasse') {
      for (var i = 0; i < saidaEmergPrimC.length; ++i) {
        if (saidaEmergPrimC[i]['row'] == x) {
          return true;
        }
      }
      return false;
    }
    if (y == 'premium') {
      for (var i = 0; i < saidaEmergPremium.length; ++i) {
        if (saidaEmergPremium[i]['row'] == x) {
          return true;
        }
      }
      return false;
    }
    if (y == 'executiva') {
      for (var i = 0; i < saidaEmergExecutiva.length; ++i) {
        if (saidaEmergExecutiva[i]['row'] == x) {
          return true;
        }
      }
      return false;
    }
    if (y == 'economica') {
      for (var i = 0; i < saidaEmergEconomica.length; ++i) {
        if (saidaEmergEconomica[i]['row'] == x) {
          return true;
        }
      }
      return false;
    }
  }

  function checkAssentoEspecial(x, y) {
    let totalPC = parseInt(totalFilPC.length);

    if (x == 'primeiraClasse') {
      for (var i = 0; i < saidaEmergPrimC.length; ++i) {
        if (saidaEmergPrimC[i]['row'] == y - 1) {
          return true;
        }
      }
      return false;
    }

    if (x == 'premium') {
      var ultimo = '';
      if (saidaEmergPrimC.length > 0) {
        let emergPC = parseInt(
          saidaEmergPrimC[saidaEmergPrimC.length - 1]['row'],
        );
        emergPC = emergPC + 1;
        if (totalPC == emergPC) {
          ultimo = emergPC;
        }
        if (ultimo == y) {
          return true;
        }
      }
      for (var i = 0; i < saidaEmergPremium.length; ++i) {
        if (saidaEmergPremium[i]['row'] == y - 1) {
          return true;
        }
      }
      return false;
    }

    if (x == 'executiva') {
      if (saidaEmergPremium.length > 0) {
        var emergPR = parseInt(
          saidaEmergPremium[saidaEmergPremium.length - 1]['row'],
        );
        emergPR = emergPR + 1;
        if (emergPR == y) {
          return true;
        }
      } else if (saidaEmergPrimC.length > 0) {
        var emergPR = parseInt(
          saidaEmergPrimC[saidaEmergPrimC.length - 1]['row'],
        );
        emergPR = emergPR + 1;
        if (emergPR == emergPR) {
          ultimo = emergPR;
        }
        if (ultimo == y) {
          return true;
        }
      }
      for (var i = 0; i < saidaEmergExecutiva.length; ++i) {
        if (saidaEmergExecutiva[i]['row'] == y - 1) {
          return true;
        }
      }
      return false;
    }

    if (x == 'economica') {
      if (saidaEmergExecutiva.length > 0) {
        var emergEX = parseInt(
          saidaEmergExecutiva[saidaEmergExecutiva.length - 1]['row'],
        );
        emergEX = emergEX + 1;
        if (emergEX == y) {
          return true;
        }
      } else if (saidaEmergPremium.length > 0) {
        var emergEX = parseInt(
          saidaEmergPremium[saidaEmergPremium.length - 1]['row'],
        );
        emergEX = emergEX + 1;
        if (emergEX == y) {
          return true;
        }
      } else if (saidaEmergPrimC.length > 0) {
        var emergEX = parseInt(
          saidaEmergPrimC[saidaEmergPrimC.length - 1]['row'],
        );
        emergEX = emergEX + 1;
        if (emergEX == y) {
          return true;
        }
      }
      for (var i = 0; i < saidaEmergEconomica.length; ++i) {
        if (saidaEmergEconomica[i]['row'] == y - 1) {
          return true;
        }
      }
      return false;
    }
  }

  function selectSeat(column, row, category) {
    useBookings.setState(
      produce((draft) => {
        draft.seat = { column, row, category };
        draft.modal.createTravelerVisible = true;
        draft.passengerType = PassengerType.PassengerCommon;
      }),
    );
  }

  function verifySeatBusy(column, row) {
    const seatsBusy = [
      ...reservedSeats,
      ...(travelers?.filter((i) => i?.seat)?.map((t) => t.seat.join('')) ?? []),
    ];
    return seatsBusy.includes(`${column}${row}`);
  }

  const convertSeatCategory = {
    economica: FlightClassCategory.Economy,
    executiva: FlightClassCategory.Business,
    premium: FlightClassCategory.Premium,
    primeiraClasse: FlightClassCategory.FirstClass,
  };

  return (
    <table className="mapaAssentos relative">
      <thead>
        <tr>
          <td></td>
          <td colSpan={totalizadorColunas()}></td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="tdBordaDir"></td>
          <td colSpan={totalizadorColunas()} className="tdBordaDir">
            <div className="saidaEmergencia pt-8">
              <div className="exit">EXIT</div>
              <div className="exit">EXIT</div>
            </div>
          </td>
        </tr>

        {/*Primeira Classe ----------------------------- */}
        <tr className={primeiraclasseFileiras.length > 1 ? '' : 'hide'}>
          <td className="tdBordaDir"></td>
          <td colSpan={totalizadorColunas()} className="tdBordaDir">
            <div
              className={
                selectClass == 'primeiraClasse'
                  ? 'divideClass fileiraAssentosSelect'
                  : 'divideClass'
              }
            >
              {nomenPC}
            </div>
          </td>
        </tr>
        {primeiraclasseFileiras.map((line, id) => (
          <tr key={id} className={id == '0' ? '' : 'fileiraUser'}>
            <td
              scope="col"
              className={line.type == 'asa' ? 'asaEsq' : 'tdBordaDir'}
            >
              <div className={id == '0' ? 'hide' : 'fileiraNumber'}>
                <button className="rownumber" disabled>
                  {line.row}
                </button>
              </div>
            </td>
            <td className={line.type == 'asa' ? 'asaDir' : 'tdBordaDir'}>
              <div className="fileiraAssentos">
                {primeiraclasseColunas.map((column, index) => (
                  <div key={index} className="flex">
                    <div className="assentos">
                      <div
                        id={column.column + line.row + 'primeiraClasse'}
                        className={
                          id == '0'
                            ? 'hide'
                            : column.type == 'corredor'
                              ? 'primeiraClasseCorredor'
                              : verifySeatBusy(column.column, line.row)
                                ? 'primeiraClasseOcupado'
                                : 'primeiraClasse'
                        }
                        onClick={() =>
                          !verifySeatBusy(column.column, line.row) &&
                          selectSeat(
                            column.column,
                            line.row,
                            convertSeatCategory[line.category],
                          )
                        }
                      >
                        {verifySeatBusy(column.column, line.row) && (
                          <span className="">{column.column + line.row}</span>
                        )}

                        <span
                          className={column.type == 'corredor' ? 'hide' : ''}
                        >
                          <div data-title="Assento de Emergência">
                            <MdOutlineStar
                              className={
                                checkAssentoEspecial('primeiraClasse', line.row)
                                  ? 'starEspecial'
                                  : 'hide'
                              }
                            />
                          </div>
                        </span>
                      </div>
                      <button
                        className={
                          line.row == '0' ? 'btnAssentosDefault' : 'hide'
                        }
                        disabled
                      >
                        {formatLabelColumn(column.column, '1')}
                      </button>
                    </div>
                    <div
                      className={
                        checkCorredor(column.column, 'primeiraClasse')
                          ? 'assentos'
                          : 'hide'
                      }
                    >
                      <div className="primeiraClasseCorredor"></div>
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={
                  checkSaidaEmerg(line.row, 'primeiraClasse')
                    ? line.type == 'asa'
                      ? 'saidaEmergenciaAsa'
                      : 'saidaEmergencia'
                    : 'hide'
                }
              >
                <div className="exit">EXIT</div>
                <div className="exit">EXIT</div>
              </div>
            </td>
          </tr>
        ))}

        {/*Premium ----------------------------- */}
        <tr className={premiumFileiras.length > 1 ? '' : 'hide'}>
          <td className="tdBordaDir"></td>
          <td colSpan={totalizadorColunas()} className="tdBordaDir">
            <div
              className={
                selectClass == 'primeiraClasse'
                  ? 'divideClass fileiraAssentosSelect'
                  : 'divideClass'
              }
            >
              {nomenPR}
            </div>
          </td>
        </tr>

        {premiumFileiras.map((line, id) => (
          <tr key={id} className={id == '0' ? '' : 'fileiraUser'}>
            <td
              scope="col"
              className={line.type == 'asa' ? 'asaEsq' : 'tdBordaDir'}
            >
              <div className={id == '0' ? 'hide' : 'fileiraNumber'}>
                <button className="rownumber" disabled>
                  {contFileiraPremium(line.row)}
                </button>
              </div>
            </td>
            <td className={line.type == 'asa' ? 'asaDir' : 'tdBordaDir'}>
              <div className="fileiraAssentos">
                {premiumColunas.map((column, index) => (
                  <div key={index} className="flex">
                    <div className="assentos">
                      <div
                        id={
                          column.column +
                          contFileiraPremium(line.row) +
                          'premium'
                        }
                        className={
                          id == '0'
                            ? 'hide'
                            : column.type == 'corredor'
                              ? 'premiumCorredor'
                              : verifySeatBusy(
                                    column.column,
                                    contFileiraPremium(line.row),
                                  )
                                ? 'assentoOcupado'
                                : 'premium'
                        }
                        onClick={() =>
                          !verifySeatBusy(
                            column.column,
                            contFileiraPremium(line.row),
                          ) &&
                          selectSeat(
                            column.column,
                            contFileiraPremium(line.row),
                            convertSeatCategory[line.category],
                          )
                        }
                      >
                        <span className="">
                          {formatLabelColumn(column.column, '2') +
                            contFileiraPremium(line.row)}
                        </span>

                        <span
                          className={column.type == 'corredor' ? 'hide' : ''}
                        >
                          <div data-title="Assento de Emergência">
                            <MdOutlineStar
                              className={
                                checkAssentoEspecial(
                                  'premium',
                                  contFileiraPremium(line.row),
                                )
                                  ? 'starEspecial'
                                  : 'hide'
                              }
                            />
                          </div>
                        </span>
                      </div>
                      <button
                        className={id == '0' ? 'btnAssentosDefault' : 'hide'}
                        disabled
                      >
                        {formatLabelColumn(column.column, '2')}
                      </button>
                    </div>
                    <div
                      className={
                        checkCorredor(column.column, 'premium')
                          ? 'assentos'
                          : 'hide'
                      }
                    >
                      <div className="premiumCorredor"></div>
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={
                  checkSaidaEmerg(contFileiraPremium(line.row), 'premium')
                    ? line.type == 'asa'
                      ? 'saidaEmergenciaAsa'
                      : 'saidaEmergencia'
                    : 'hide'
                }
              >
                <div className="exit">EXIT</div>
                <div className="exit">EXIT</div>
              </div>
            </td>
          </tr>
        ))}

        {/*Exevutiva ----------------------------- */}

        <tr className={executivaFileiras.length > 1 ? '' : 'hide'}>
          <td className="tdBordaDir"></td>
          <td colSpan={totalizadorColunas()} className="tdBordaDir">
            <div
              className={
                selectClass == 'primeiraClasse'
                  ? 'divideClass fileiraAssentosSelect'
                  : 'divideClass'
              }
            >
              {nomenEX}
            </div>
          </td>
        </tr>

        {executivaFileiras.map((line, id) => (
          <tr key={id} className={id == '0' ? '' : 'fileiraUser'}>
            <td
              scope="col"
              className={line.type == 'asa' ? 'asaEsq' : 'tdBordaDir'}
            >
              <div className={id == '0' ? 'hide' : 'fileiraNumber'}>
                <button className="rownumber" disabled>
                  {contFileiraExecutiva(line.row)}
                </button>
              </div>
            </td>
            <td className={line.type == 'asa' ? 'asaDir' : 'tdBordaDir'}>
              <div className="fileiraAssentos">
                {executivaColunas.map((column, index) => (
                  <div key={index} className="flex">
                    <div key={index} className="assentos">
                      <div
                        id={
                          column.column +
                          contFileiraExecutiva(line.row) +
                          'executiva'
                        }
                        className={
                          id == '0'
                            ? 'hide'
                            : column.type == 'corredor'
                              ? 'executivaCorredor'
                              : verifySeatBusy(
                                    column.column,
                                    contFileiraExecutiva(line.row),
                                  )
                                ? 'assentoOcupado'
                                : 'executiva'
                        }
                        onClick={() =>
                          !verifySeatBusy(
                            column.column,
                            contFileiraExecutiva(line.row),
                          ) &&
                          selectSeat(
                            column.column,
                            contFileiraExecutiva(line.row),
                            convertSeatCategory[line.category],
                          )
                        }
                      >
                        {verifySeatBusy(
                          column.column,
                          contFileiraExecutiva(line.row),
                        ) && (
                          <span className="">
                            {column.column + contFileiraExecutiva(line.row)}
                          </span>
                        )}
                        <span
                          className={column.type == 'corredor' ? 'hide' : ''}
                        >
                          <div data-title="Assento de Emergência">
                            <MdOutlineStar
                              className={
                                checkAssentoEspecial(
                                  'executiva',
                                  contFileiraExecutiva(line.row),
                                )
                                  ? 'starEspecial'
                                  : 'hide'
                              }
                            />
                          </div>
                        </span>
                      </div>
                      <button
                        className={id == '0' ? 'btnAssentosDefault' : 'hide'}
                        disabled
                      >
                        {formatLabelColumn(column.column, '3')}
                      </button>
                    </div>
                    <div
                      className={
                        checkCorredor(column.column, 'executiva')
                          ? 'assentos'
                          : 'hide'
                      }
                    >
                      <div className="executivaCorredor"></div>
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={
                  checkSaidaEmerg(contFileiraExecutiva(line.row), 'executiva')
                    ? line.type == 'asa'
                      ? 'saidaEmergenciaAsa'
                      : 'saidaEmergencia'
                    : 'hide'
                }
              >
                <div className="exit">EXIT</div>
                <div className="exit">EXIT</div>
              </div>
            </td>
          </tr>
        ))}

        {/*Econômica ----------------------------- */}
        <tr className={economicaFileiras.length > 0 ? '' : 'hide'}>
          <td className="tdBordaDir"></td>
          <td colSpan={totalizadorColunas()} className="tdBordaDir">
            <div
              className={
                selectClass == 'primeiraClasse'
                  ? 'divideClass fileiraAssentosSelect'
                  : 'divideClass'
              }
            >
              {nomenEC}
            </div>
          </td>
        </tr>

        {economicaFileiras.map((line, id) => (
          <tr key={id} className={id == '0' ? '' : 'fileiraUser'}>
            <td
              scope="col"
              className={line.type == 'asa' ? 'asaEsq' : 'tdBordaDir'}
            >
              <div className={id == '0' ? 'hide' : 'fileiraNumber'}>
                <button className="rownumber" disabled>
                  {contFileiraEconomica(line.row)}
                </button>
              </div>
            </td>
            <td className={line.type == 'asa' ? 'asaDir' : 'tdBordaDir'}>
              <div className="fileiraAssentos">
                {economicaColunas.map((column, index) => (
                  <div key={index} className="flex">
                    <div key={index} className="assentos">
                      <div
                        id={
                          column.column +
                          contFileiraEconomica(line.row) +
                          'economica'
                        }
                        className={
                          id == '0'
                            ? 'hide'
                            : column.type == 'corredor'
                              ? 'economicaCorredor'
                              : verifySeatBusy(
                                    column.column,
                                    contFileiraEconomica(line.row),
                                  )
                                ? 'assentoOcupado'
                                : 'economica'
                        }
                        onClick={() =>
                          !verifySeatBusy(
                            column.column,
                            contFileiraEconomica(line.row),
                          ) &&
                          selectSeat(
                            column.column,
                            contFileiraEconomica(line.row),
                            convertSeatCategory[line.category],
                          )
                        }
                      >
                        {verifySeatBusy(
                          column.column,
                          contFileiraEconomica(line.row),
                        ) && (
                          <span className="">
                            {column.column + contFileiraEconomica(line.row)}
                          </span>
                        )}
                        <span
                          className={column.type == 'corredor' ? 'hide' : ''}
                        >
                          <div data-title="Assento de Emergência">
                            <MdOutlineStar
                              className={
                                checkAssentoEspecial(
                                  'economica',
                                  contFileiraEconomica(line.row),
                                )
                                  ? 'starEspecial'
                                  : 'hide'
                              }
                            />
                          </div>
                        </span>
                      </div>
                      <button
                        className={id == '0' ? 'btnAssentosDefault' : 'hide'}
                        disabled
                      >
                        {formatLabelColumn(column.column, '4')}
                      </button>
                    </div>
                    <div
                      className={
                        checkCorredor(column.column, 'economica')
                          ? 'assentos'
                          : 'hide'
                      }
                    >
                      <div className="economicaCorredor"></div>
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={
                  checkSaidaEmerg(contFileiraEconomica(line.row), 'economica')
                    ? line.type == 'asa'
                      ? 'saidaEmergenciaAsa'
                      : 'saidaEmergencia'
                    : 'hide'
                }
              >
                <div className="exit">EXIT</div>
                <div className="exit">EXIT</div>
              </div>
            </td>
          </tr>
        ))}

        {minFileiras.map((line, index) => (
          <tr
            key={index}
            className={
              index > minFileiras.length - totalizadorFileiras() ? 'hide' : ''
            }
          >
            <td className="tdBordaDir"></td>
            <td colSpan={totalizadorColunas()} className="tdBordaDir">
              <div className="divideClass"></div>
            </td>
          </tr>
        ))}

        <tr>
          <td className="tdBordaDir"></td>
          <td colSpan={totalizadorColunas()} className="tdBordaDir">
            <div className="saidaEmergencia fundoEmergencia">
              <div className="exit">EXIT</div>
              <div className="exit">EXIT</div>
            </div>
          </td>
        </tr>
        <tr>
          <td></td>
          <td colSpan={totalizadorColunas()}>
            <div className="frundoAeronave"></div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
