import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { InputText } from '@components/input';
import LoadingAnimation from '@components/LoadingAnimation';
import { ButtonV2 } from '@components/new-components/button-v2';
import { Form } from '@components/new-components/form';
import { SelectV2 } from '@components/new-components/select-v2';
import PageHeader from '@components/page-header';
import {
  SELECT_OPTIONS_ADMIN,
  SELECT_OPTIONS_BUYER_ADMIN,
} from '@data/roles.data';
import AnalyticsService from '@services/analytics/analytics';
import events from '@services/analytics/events';
import api from '@services/api';
import { useAuthentication } from '@stores/authentication';
import { Role } from '@system/acl';
import { PagePath } from '../../pages-config';
import { ModalUpdateConfirmation } from './components/ModalUpdateConfirmation';
import { ModalUpdateSuccess } from './components/ModalUpdateSuccess';

export function AdminUsuarioEdit() {
  const { session } = useAuthentication();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const { id } = useParams();
  const [agencies, setAgencies] = useState([]);

  const {
    handleSubmit,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: '',
      familyName: '',
      email: '',
      cpf: '',
      agencyId: '',
      roleId: '',
      status: '',
    },
  });

  const init = useCallback(async () => {
    setLoading(true);
    try {
      const [{ data: resUser }, { data: resAgencies }] = await Promise.all([
        api.get(`/users/${id}/admin`),
        api.get('agencies'),
      ]);
      console.log('🚀 ~ init ~ resUser:', resUser);

      setAgencies(
        resAgencies?.sort((a, b) =>
          a.razaosocial.localeCompare(b.razaosocial),
        ) ?? [],
      );

      setValue('firstName', resUser.firstName);
      setValue('familyName', resUser.familyName);
      setValue('email', resUser.email);
      setValue('cpf', formatCpf(resUser.cpf));
      setValue('agencyId', resUser.agenciaCliente);
      setValue('roleId', resUser.status);
      setValue('status', resUser.userActive);
    } catch (error) {
      console.error(error);
      alert('Erro ao carregar dados do usuário');
    } finally {
      setLoading(false);
    }
  }, [id, setValue]);

  useEffect(() => {
    init();
  }, [id, init]);

  async function onSubmit() {
    setIsModalOpen(true);

    AnalyticsService.trackEvent(
      events.USER_LIST_EDIT_CONFIRMATION_MODAL_PAGE_VIEWED,
    );
  }

  async function handleConfirmModal() {
    AnalyticsService.trackEvent(
      events.USER_LIST_EDIT_CONFIRMATION_MODAL_BUTTON_CLICKED,
    );

    setIsUpdateLoading(true);

    try {
      await api.put(`users/${id}/admin`, {
        email: watch('email'),
        agency: watch('agencyId'),
        status: watch('roleId'),
        userActive: Number(watch('status')),
        firstName: watch('firstName'),
        familyName: watch('familyName'),
        cpf: watch('cpf'),
      });
      setIsUpdateLoading(false);
      setIsSuccessModalOpen(true);

      AnalyticsService.trackEvent(
        events.USER_LIST_EDIT_CONFIRMATION_SUCCESS_MODAL_PAGE_VIEWED,
      );
    } catch (error) {
      console.error('Error updating user agency or role:', error);
      alert('Ocorreu um erro ao atualizar a empresa ou perfil do usuário.');
    } finally {
      setIsUpdateLoading(false);
    }
  }

  function handleSuccessClose() {
    setIsSuccessModalOpen(false);
    navigate(PagePath.AdminUsuarios);
  }

  const SELECT_OPTIONS_COMPANIES = agencies
    ?.filter((data) => {
      return (
        session.role === Role.Administrador ||
        (session.role === Role.CompradorAdministrador &&
          data.nome_fantasia === session.agency.nomeFantasia)
      );
    })
    .map((data) => ({
      value: data.id,
      label: data.nome_fantasia,
    }));

  function handleCpfChange(event) {
    const { value } = event.target;
    const formattedValue = formatCpf(value);
    setValue('cpf', formattedValue);
  }

  function formatCpf(value) {
    if (!value) return '';

    return value
      .replace(/\D/g, '')
      .slice(0, 11)
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  }

  return (
    <>
      <ModalUpdateConfirmation
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmModal}
        isLoading={isUpdateLoading}
      />
      <ModalUpdateSuccess
        isOpen={isSuccessModalOpen}
        onClose={handleSuccessClose}
      />
      {loading && <LoadingAnimation />}

      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Configurações', link: PagePath.Admin },
          { label: 'Usuários', link: PagePath.AdminUsuarios },
          { label: 'Dados do usuário', link: PagePath.AdminUsuarioEdit },
        ]}
        title="Dados do usuário"
      />

      <Form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <div className="flex flex-col items-center gap-5 rounded-md bg-white p-4">
          <div className="m-0 flex w-full border-b border-l-0 border-r-0 border-t-0 border-solid border-[#EBEBEB] pb-4">
            <h4 className="m-0 text-xl text-primary">Dados pessoais</h4>
          </div>
          <div className="grid w-[100%] grid-cols-12 gap-4">
            <Form.Group className="col-span-6">
              <Form.Label>Nome</Form.Label>
              <InputText
                placeholder="Maria"
                placeholderStyle="normal"
                error={errors?.firstName?.message ?? ''}
                {...register(`firstName`, {
                  required: {
                    message: 'Campo obrigatório',
                    value: true,
                  },
                  minLength: 3,
                })}
              />
            </Form.Group>
            <Form.Group className="col-span-6">
              <Form.Label>Sobrenome</Form.Label>
              <InputText
                placeholder="da Silva"
                placeholderStyle="normal"
                error={errors?.familyName?.message ?? ''}
                {...register(`familyName`, {
                  required: {
                    message: 'Campo obrigatório',
                    value: true,
                  },
                  minLength: 3,
                })}
              />
            </Form.Group>
            <Form.Group className="col-span-6">
              <Form.Label>CPF</Form.Label>
              <InputText
                placeholder="001.001.002-12"
                placeholderStyle="normal"
                error={errors?.cpf?.message ?? ''}
                {...register(`cpf`, {
                  required: {
                    message: 'Campo obrigatório',
                    value: true,
                  },
                  minLength: 3,
                  onChange: handleCpfChange,
                })}
              />
            </Form.Group>
            <Form.Group className="col-span-6">
              <Form.Label>E-mail</Form.Label>
              <InputText
                placeholder="exemplo@exemplo.com"
                placeholderStyle="normal"
                error={errors?.email?.message ?? ''}
                {...register(`email`, {
                  required: {
                    message: 'Campo obrigatório',
                    value: true,
                  },
                  minLength: 3,
                })}
              />
            </Form.Group>
            <div className="col-span-12 my-2 h-[1px] bg-neutral-300"></div>
            <Form.Group className="col-span-6">
              <Form.Label>Empresa</Form.Label>
              <SelectV2
                options={SELECT_OPTIONS_COMPANIES}
                placeholder="Selecione"
                placeholderStyle="normal"
                value={watch('agencyId')}
                {...register('agencyId', { required: true })}
              />
            </Form.Group>
            <Form.Group className="col-span-3">
              <Form.Label>Perfil</Form.Label>
              <SelectV2
                options={[
                  ...(session.role === Role.Administrador
                    ? SELECT_OPTIONS_ADMIN
                    : SELECT_OPTIONS_BUYER_ADMIN),
                ]}
                value={watch('roleId')}
                placeholder="Selecione"
                placeholderStyle="normal"
                {...register('roleId', { required: true })}
              />
            </Form.Group>
            <Form.Group className="col-span-3">
              <Form.Label>Status</Form.Label>
              <SelectV2
                options={[
                  { value: 1, label: 'Ativo' },
                  { value: 0, label: 'Inativo' },
                ]}
                value={watch('status')}
                {...register('status', { required: true })}
                placeholder="Selecione"
                placeholderStyle="normal"
              />
            </Form.Group>
          </div>
        </div>

        <div className="flex flex-row justify-between">
          <ButtonV2
            type="button"
            ghost
            size="large"
            className="w-2/12"
            onClick={() => navigate(PagePath.AdminUsuarios)}
          >
            <ButtonV2.Text>Voltar</ButtonV2.Text>
          </ButtonV2>
          <ButtonV2 size="large" className="w-2/12">
            <ButtonV2.Text>Salvar</ButtonV2.Text>
          </ButtonV2>
        </div>
      </Form>
    </>
  );
}
