import PropTypes from 'prop-types';

const LoadingComponent = ({ loadingText, loadingIcon }) => {
  return (
    <div className="flex flex-col items-center justify-center text-sm text-neutral-800">
      <div className="loading-overlay">
        <video
          autoPlay
          loop
          muted
          className="loading-icon"
          style={{ width: '100px', height: '100px' }}
        >
          <source src={loadingIcon} type="video/webm" />
          Seu navegador não suporta vídeos WebM.
        </video>
      </div>
      <p
        className="mt-4 text-center"
        dangerouslySetInnerHTML={{ __html: loadingText }}
      />
    </div>
  );
};

LoadingComponent.propTypes = {
  loadingText: PropTypes.string.isRequired,
  loadingIcon: PropTypes.string.isRequired,
};

export default LoadingComponent;
